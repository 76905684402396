import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  LargeCard,
  ListCard,
  RowCard,
  SimpleCard,
} from "../../components/shipment-managment/[id]/card";

import { formatCountryCurrency } from "../../../../utils/formatCountryCurrency";
import { formatUnix } from "../../utils/formatUnix";
import { MainLayout } from "../../layouts/MainLayout";
import useStyles from "./styles";
import { OrderShipmentSkeleton } from "./[orderSkeleton]";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import Divider from '@mui/material/Divider';
import { convertToCelcius } from "../../../../utils/convertToCelcius";
import ReserveDateModal from "./ChooseReservedDateModal";
import { toUnixTimestamp } from "../../../../utils/time/unix";
import { db, FieldValue, updateDoc } from "../../../../firebase/database";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useState } from "react";

export const Comment = ({comment,withIcon=true, reason, temperature}) => {

  const reasonAndTemperatureExist = reason && temperature
  const reasonOrTemperatureExist = reason || temperature
  const differentFromVoidString = (str) => str !== "";
  const allValuesVoid = differentFromVoidString(comment) || differentFromVoidString(temperature) || differentFromVoidString(reason)

  if(!allValuesVoid) return;
  return allValuesVoid && <Stack sx={{backgroundColor:'#f5f5f5', outline:'1px solid #d4d4d4', borderRadius:'6px', borderStartStartRadius:0}}>
  {differentFromVoidString(comment) && <Stack alignItems='center' padding="12px" direction='row' gap='6px'>
    {withIcon && <CommentRoundedIcon  sx={{fontSize:18, color:"#737373" }}/>}
    <Typography component="span">{comment}</Typography>
  </Stack>}
  {comment && reasonOrTemperatureExist && <Divider />}
  {reasonOrTemperatureExist && <Stack direction="row" gap="6px" flexWrap="wrap" padding="12px" >
  {reason && <Chip
      style={{
        fontWeight: 600,
        backgroundColor: "#e5e5e5",
        color: "#525252",
        borderRadius:4
      }}
      label={reason}
    />}
    {reasonAndTemperatureExist && comment && <Divider orientation="vertical" variant="middle" flexItem />}
    {temperature && <Chip
      style={{
        fontWeight: 600,
        backgroundColor: "#e5e5e5",
        color: "#525252",
        borderRadius:4
      }}
      label={temperature}
    />}
  </Stack>}
  </Stack>
}

const OrderReservedDeliveryDateHandler = ({purchaseOrderSeller, reservedDateDelivery, purchaseOrderID,avlScheduleDate}) => {
  const [isUpdatingReservedDate, setIsUpdatingReservedDate] = useState(false)
  const [hasError, setHasError] = useState({error: false, message: ''})

  const cancelReservedDateHandler = async (selectedDate) => {
    await updateDoc({
      indexName: "sellers",
      id: purchaseOrderSeller,
      hit: {
        availableSchedule: FieldValue.arrayUnion(selectedDate[0].ts)
      },
    });
    await updateDoc({
      indexName: "purchaseOrders",
      id: purchaseOrderID,
      hit: { "reservedDateDelivery": FieldValue.delete()  },
    });
  }

  const checkIfSellerHasSelectedData = async (selectedDate) => {
    const sellerRef = db.collection('sellers').doc(purchaseOrderSeller);
    const sellerDoc = await sellerRef.get();
    if(!sellerDoc.exists) return setHasError({error: true, message: 'Error. Intente de nuevo'});
    const sellerData = sellerDoc.data();
    const docHasSelectedDate = sellerData?.availableSchedule.filter(e => selectedDate[0].ts === e) || []

    if(!docHasSelectedDate.length) {
      setHasError({error: true, message: 'El horario seleccionado no existe'});
      setIsUpdatingReservedDate(false);
      throw new Error('Bad request!')
    }
  }

  const acceptSelectedDateHandler = async (selectedDate) => {
      await updateDoc({
        indexName: "sellers",
        id: purchaseOrderSeller,
        hit: {
          availableSchedule: FieldValue.arrayRemove(selectedDate[0].ts)
        },
      });
      await updateDoc({
        indexName: "purchaseOrders",
        id: purchaseOrderID,
        hit: { "reservedDateDelivery": selectedDate[0].ts  },
      });
  }

  const modifyReserveDateAccept = ({handleClose}) => 
    <Button onClick={handleClose} autoFocus>
      Aceptar
    </Button>
  const modifyReserveDateCancel = ({handleClose, selectedDate}) => <Button
    loading={isUpdatingReservedDate}
    disabled={isUpdatingReservedDate}
    onClick={async ()=> {
      handleClose()
      setIsUpdatingReservedDate(true)
      await cancelReservedDateHandler(selectedDate)
      setIsUpdatingReservedDate(false)
    }} color='error'>Cancelar horario</Button>

  const reserveDateCancelButton = ({ handleClose, resetSelectedDate }) => <Button onClick={()=> {
    handleClose()
    resetSelectedDate()
  }} color='error'>Cancelar</Button>

  const reserveDateAcceptButton = ({handleClose, isSelectedDateNotComplete, resetSelectedDate, selectedDate, onAcceptDate}) => 
    <Button 
      loading={isUpdatingReservedDate} 
      disabled={hasError.error || isSelectedDateNotComplete || isUpdatingReservedDate}
      onClick={async () => {
        try {
          setIsUpdatingReservedDate(true)
          await checkIfSellerHasSelectedData(selectedDate)
          await onAcceptDate(selectedDate);
          resetSelectedDate();
          setIsUpdatingReservedDate(false)
          handleClose();
        } catch(err) {
        // console.log(err)
        }
      }} autoFocus>
      Aceptar
    </Button>
    
  const onChangeSelectedDate = () => setHasError({error: false, message: ''})
  return <> {
    (!reservedDateDelivery ? (
    <ReserveDateModal
      modalButton={(handleClick) => <Button variant="contained" 
      sx={
        {
          "&.MuiButton-contained": {
            backgroundColor: "#3b82f6",
            color: "#eff6ff"
          }
        }
      }
      onClick={handleClick}>Reservar un turno</Button>} 
      error={hasError}
      onChangeCallback={onChangeSelectedDate}
      onAcceptDate={acceptSelectedDateHandler}
      buttonCancel={reserveDateCancelButton}
      buttonAccept={reserveDateAcceptButton}
      avlScheduleDate={avlScheduleDate} />)

      : <Stack direction="row" gap={1} alignItems="center">
            {formatUnix(reservedDateDelivery, "DD/MM/YYYY HH:mm")}
          <ReserveDateModal
            headerLabel="Editar el turno reservado"
            error={hasError}
            modalButton={(handleClickOpen) => <IconButton onClick={handleClickOpen}><EditIcon/></IconButton>}
            defaultStaticPickerValue={[{
              day: toUnixTimestamp(reservedDateDelivery, "DD/MM/YYYY"),
              hours: toUnixTimestamp(reservedDateDelivery, "h:mm a"),
              ts: reservedDateDelivery
            }]}
            onChangeCallback={onChangeSelectedDate}
            buttonCancel={modifyReserveDateCancel}
            buttonAccept={modifyReserveDateAccept}
            onAcceptDate={acceptSelectedDateHandler} 
            avlScheduleDate={[
              {
                day: toUnixTimestamp(reservedDateDelivery, "DD/MM/YYYY"),
                hours: toUnixTimestamp(reservedDateDelivery, "h:mm a"),
                ts: reservedDateDelivery
              }
              ]} />
        </Stack>)}</>
}

function diff_lt(a, b, percent = 0.01) {
  return Math.abs((a||0) - (b||0)) < ((a||0) + (b||0)) * percent / 2;
}

export function OrderShipmentPresentation({
  rows,
  purchaseOrderData,
  sellerData,
  loading,
  colVisibilityModel,
  products,
  inventoryAdjustmentData,
  inventorynumbersData,
  columns,
}) {
  const classes = useStyles();
  const controlReason = inventoryAdjustmentData && inventoryAdjustmentData.data.controlReason
  const allValuesVoid = controlReason && controlReason?.comment !== "" || controlReason?.temperature !== "" || controlReason?.reason !== "";
  const reservedDateDelivery = purchaseOrderData?.reservedDateDelivery;
  const purchaseOrderID = purchaseOrderData?.objectID;
  const purchaseOrderSeller = purchaseOrderData?.seller;
  const avlScheduleDate = !loading && sellerData?.data?.availableSchedule && 
    sellerData?.data?.availableSchedule.map(ts => ({
      day: toUnixTimestamp(ts, "DD/MM/YYYY"),
      hours: toUnixTimestamp(ts, "h:mm a"),
      ts: ts

    }));

  if (loading) return <OrderShipmentSkeleton />;
  const purchaseOrder = purchaseOrderData;

  const totalRequestedItems = purchaseOrderData
    ? Object.values(purchaseOrder.items || {})
        .map((el) => el?.q)
        .reduce((acc, current) => acc + current, 0)
    : null;

  const totalReceivedItems = inventoryAdjustmentData
    ? Object.values(inventoryAdjustmentData?.data.items)
        .map((e) =>
          Object.values(e)
            .map((e) => Object.values(e)[0])
            .reduce((acc, current) => Number(acc) + Number(current), 0),
        )
        .reduce((acc, current) => Number(acc) + Number(current), 0)
    : 0;
  const getReceivedByPercentage = (total, q) => (q * 100) / total;

  const unitsStatusPercentage = getReceivedByPercentage(
    totalRequestedItems,
    totalReceivedItems,
  );

  const cardLargeHeader =
    purchaseOrder?.received.s === 0
      ? "Esperando a ser entregado"
      : unitsStatusPercentage === 100
      ? "Procesado exitosamente"
      : unitsStatusPercentage < 100 && unitsStatusPercentage > 90
      ? "Procesado con algunos problemas"
      : unitsStatusPercentage < 90 && unitsStatusPercentage > 50
      ? "Procesado con algunos problemas"
      : unitsStatusPercentage < 50 && unitsStatusPercentage > 0
      ? "Procesado con serios problemas"
      : unitsStatusPercentage <= 0
      ? "No pudo ser procesado"
      : "";

  const cardLargeSubHeader =
    purchaseOrder?.received.s === 0
      ? "Esperando a ser entregado"
      : unitsStatusPercentage === 100
      ? "Procesado con éxito"
      : unitsStatusPercentage < 100
      ? "Procesado con problemas"
      : unitsStatusPercentage <= 0
      ? "No pudo ser procesado"
      : "";


  return (
    <MainLayout>
      <Stack gap={6}>
        <Stack gap={2}>
          <Typography
            sx={{ fontSize: 20, fontWeight: 700 }}
            component="h4"
            color="text.secondary"
          >
            Resumen de envío
          </Typography>   
          <Stack direction="row" gap={2} flexWrap="wrap">
            <Box flexGrow={1}>
              <RowCard
                rows={[
                  {
                    header: "Fecha de creación",
                    content: purchaseOrder?.date_timestamp && formatUnix(purchaseOrder.date_timestamp, "DD/MM/YYYY")
                  },
                  (purchaseOrder?.received?.t && {
                    header: "Fecha de recepción",
                    content: purchaseOrder?.received?.t
                      ? formatUnix(purchaseOrder?.received?.t, "DD/MM/YYYY HH:mm")
                      : "",
                  }),
                  (!purchaseOrder?.received?.t && {
                    header: "Horario reservado",
                    content: <> {!loading && <OrderReservedDeliveryDateHandler
                      purchaseOrderSeller={purchaseOrderSeller}
                      reservedDateDelivery={reservedDateDelivery}
                      avlScheduleDate={avlScheduleDate} 
                      purchaseOrderID={purchaseOrderID}/>} </>
                  }),
                  {
                    header: "Estado de entrega",
                    content:
                      purchaseOrder?.received?.s === 1 ||
                      purchaseOrder?.received?.t ? (
                        <Chip
                          style={{
                            fontWeight: 600,
                            backgroundColor: "#bbf7d0",
                            color: "#14532d",
                          }}
                          label={"Recibido"}
                        />
                      ) : (
                        <Chip
                          style={{
                            fontWeight: 600,
                            backgroundColor: "#fef08a",
                            color: "#713f12",
                          }}
                          label={"No Recibido"}
                        />
                      ),
                  },
                  {
                    header: allValuesVoid && "Comentarios",
                    content: controlReason && allValuesVoid &&  <Comment
                      reason={controlReason?.reason}
                      temperature={convertToCelcius(controlReason?.temperature)}
                      comment={controlReason?.comment}/>
                  },
                ]}
              />
            </Box>
            {/* <Box flexGrow={1}>
              <ListCard
                list={[
                  {
                    header: "Pallets declarados / ingresados",
                    content: "Content",
                  },
                  {
                    header: "Bultos declarados / ingresados",
                    content: "Content",
                  },
                  {
                    divider: true,
                    header: "Unidades declaradas / ingresados",
                    content: "Content",
                  },
                ]}
              />
            </Box> */}
            {/* <Box flexGrow={1}>
              <ListCard
                list={[
                  { header: "Cargos", content: "Content" },
                  { header: "Incumplimientos", content: "Content" },
                  { header: "Colecta", content: "Content" },
                  {
                    divider: true,
                    header: "Total",
                    content: "Content",
                  },
                ]}
              />
            </Box> */}
            {purchaseOrder?.subTotal &&
              purchaseOrder?.iva &&
              purchaseOrder?.total && (
                <Box flexGrow={1}>
                  <ListCard
                    list={[
                      {
                        header: "Subtotal",
                        content: formatCountryCurrency(purchaseOrder?.subTotal),
                      },
                      {
                        header: "IVA",
                        content: formatCountryCurrency(purchaseOrder?.iva),
                      },
                      {
                        divider: true,
                        header: "Total",
                        content: formatCountryCurrency(purchaseOrder?.total),
                      },
                    ]}
                  />
                </Box>
              )}
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography
            sx={{ fontSize: 20, fontWeight: 700 }}
            component="h4"
            color="text.secondary"
          >
            Estado y contenido del envío
          </Typography>
          <Stack gap={2}>
            {purchaseOrder?.received.s === 1 && (
              <LargeCard
                progressBarValue={loading ? 0 : unitsStatusPercentage}
                unitsDeclaredData={[
                  {
                    header: `${totalRequestedItems} u.`,
                    color: "#374151",
                    subHeader: "Unidades declaradas / pedidas",
                  },
                  {
                    header:
                      purchaseOrder?.received.s === 0
                        ? "---"
                        : `+${totalReceivedItems} u.`,
                    color: "#9ca3af",
                    subHeader: "Unidades recibidas",
                  },
                  {
                    header:
                      purchaseOrder?.received.s === 0
                        ? "---"
                        : `-${totalRequestedItems - totalReceivedItems} u.`,
                    color: "#9ca3af",
                    subHeader: "Unidades que no llegaron",
                  },
                ]}
                state={
                  unitsStatusPercentage < 100
                    ? "warning"
                    : unitsStatusPercentage === 100
                    ? "success"
                    : unitsStatusPercentage < 50
                    ? "danger"
                    : ""
                }
                header={cardLargeHeader}
                subheading={cardLargeSubHeader}
              />
            )}

            <Stack direction="row" gap={2} flexWrap="wrap">
              <SimpleCard
                header="Unidades declaradas / pedidas"
                content={totalRequestedItems}
              />
              <SimpleCard
                header="Unidades recibidas"
                content={
                  purchaseOrder?.received.s === 0 ? "---" : totalReceivedItems
                }
              />
              <SimpleCard
                header="Unidades que no llegaron"
                content={
                  purchaseOrder?.received.s === 0
                    ? "---"
                    : totalRequestedItems - totalReceivedItems
                }
              />
            </Stack>
          </Stack>
          <Box
            style={{ position: "relative", width: "100%", height: "500px" }}
            sx={{
              height: 700,
              overflow: "auto",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              rows={rows}
              // className={classes}
              columns={columns}
              getRowHeight={() => "auto"}
              columnVisibilityModel={colVisibilityModel}
              getRowClassName={(el) => {
                if (!purchaseOrder?.received.s) return; // check if received
                if (!inventoryAdjustmentData?.data) return; // check if inventory adjustment data exists

                const productInvoicedUnits = inventoryAdjustmentData?.data?.invoicedUnits?.[el.row.id];
                const productReceivedQuantity = inventorynumbersData
                  .filter(e => e?.product?.product === el?.row?.id)
                  .reduce((prev, current) => prev + (current?.product?.received), 0);

                const productReceivedWeightInGrams = inventorynumbersData
                  .filter(e => e?.product?.product === el?.row?.id)
                  .reduce((prev, current) => prev + (current?.product?.weight?.received), 0);

                const productReceivedWeightInKg = productReceivedWeightInGrams / 1000
                const isProductByWeight = products.some(e => {
                      if(e.data.price_by === "weight" && e.data.objectID === el.row.id){
                        return true;
                      }
                      return false;
                    }
                )

                if(isProductByWeight){
                  if(diff_lt(productReceivedWeightInKg, productInvoicedUnits, 0.10)){
                    return classes.greenRow;
                  }
                  return classes.yellowRow;
                }

                if(productReceivedQuantity === productInvoicedUnits){
                  return classes.greenRow;
                }
                return classes.yellowRow;
              }}
              style={{ position: "absolute", height: "100%", width: "100%" }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Stack>
      </Stack>
    </MainLayout>
  );
}
