import {
  Avatar,
  Button,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { numberWithCommas } from "../../../../components/Utils/NumberWithCommas";
import {
  db,
  FieldValue,
  newDoc,
  updateDoc,
} from "../../../../firebase/database";
import { CloseOutlined, LocalPrintshop } from "@material-ui/icons";
import ErrorIcon from "@mui/icons-material/Error";
import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
import { floor, pick } from "lodash";
import moment from "moment";
import { round } from "lodash";

const fieldsProduct = [
  "name",
  "price_by",
  "quantity",
  "taste",
  "brandName",
  "EAN",
  "objectID",
  "unit",
];

export const ModalPrint = ({ open, close, data }) => {
  const {
    product,
    q,
    e,
    w,
    lot,
    binId,
    printEditingUnit,
    setEditingUnit,
    pack,
    packWeight,
  } = data;

  const printTypes = {
    1: "total",
    2: "unit",
    3: "box",
  };

  const printType = printTypes[open];

  const productDTO = pick(product, fieldsProduct);

  if (!open) return null;
  const [debounce, setDebounce] = useState(false);

  const handleChangeUnit = useCallback(async (unit) => {
    await updateDoc({
      id: binId,
      indexName: "bins",
      hit: {
        printEditingUnit: unit,
      },
    });
  }, []);

  const isWeightProduct = product?.price_by === "weight";
  const kg = w / 1000;

  const totalsTypes = {
    total: isWeightProduct ? kg : q,
    unit: isWeightProduct ? kg / q : 1,
    box: isWeightProduct ? kg / (q / pack) : q / pack,
  };

  const total = isWeightProduct ? floor(kg) : q;

  const totalUnit = floor(totalsTypes[printType]);

  const unit = isWeightProduct ? " Kg" : "U";

  const neto =
    isWeightProduct &&
    Number(printEditingUnit?.unit || 0) -
      (pack > 1 ? ((packWeight || 0) / 1000) * floor(Number(q) / pack) : 0);

  const [[{ totalUnits } = {}] = []] = useMongoAggregate({
    index: "prints",
    debounce,
    aggregate: [
      {
        $match: {
          "data.binData.lot": lot,
          "data.binData.printType": printType,
        },
      },
      {
        $project: {
          unit: { $toInt: "$data.data.printEditingUnit.unit" },
          _id: 0,
        },
      },
      {
        $group: {
          _id: null,
          totalUnits: { $sum: "$unit" },
        },
      },
    ],
  });

  const openKeyboard = () => {
    setEditingUnit(true);
    handleChangeUnit({
      ...printEditingUnit,
      ...{ type: isWeightProduct ? "w" : "q" },
    });
  };

  const closeDialog = async () => {
    handleChangeUnit(null);
    close();
  };

  const isAllPrinted = totalUnits >= total;

  const margin = totalUnit * 0.1; // Margen del 10%
  const adjustedTotalMin = totalUnit - margin;
  const adjustedTotalMax = totalUnit + margin;

  const isWithinMargin =
    +printEditingUnit?.unit >= adjustedTotalMin &&
    +printEditingUnit?.unit <= adjustedTotalMax;

  const marginStatus =
    isWithinMargin && +printEditingUnit?.unit !== totalUnit && !isAllPrinted
      ? "Hay diferencia respecto al total"
      : "";

  const isValidTotal =
    +printEditingUnit?.unit >= adjustedTotalMin &&
    +printEditingUnit?.unit <= adjustedTotalMax;

  const disabledStates = {
    total: false,
    unit:
      !+printEditingUnit?.unit > 0 || !printEditingUnit?.unit || isWeightProduct
        ? !isValidTotal
        : false,
    box:
      !+printEditingUnit?.unit > 0 || !printEditingUnit?.unit || isWeightProduct
        ? !isValidTotal
        : false,
  };

  const isDisabled = disabledStates[printType];

  const printed = isAllPrinted
    ? `Ya se imprimió el total de este lote`
    : totalUnits > 0
    ? `Ya se imprimió el equivalente a ${totalUnits} ${unit}`
    : false;

  const generateQR = () => {
    const unitTypes = {
      total: q,
      unit: 1,
      box: pack,
    };
    const unit = unitTypes[printType];

    const weight =
      printType === "total"
        ? kg * 1000
        : printType === "box"
        ? neto * 1000
        : printEditingUnit?.unit * 1000;

    return `https://qr.plant.com.ar/lot/${lot}/${unit} ${
      isWeightProduct
        ? `${`/${weight}`}${
            printType === "total"
              ? `/${parseInt(q / pack, 10)}`
              : printType === "box"
              ? "/1"
              : ""
          }`
        : ""
    } `;
  };

  const print = async () => {
    const qrData = generateQR();

    const printByWeight = async () => {
      const totalBoxQ = parseInt(q / pack, 10);

      const editingUnit =
        printType === "total"
          ? {
              isBox: true,
              boxQ: totalBoxQ,
              w: kg,
              q,
            }
          : {
              isBox: printType === "box",
              boxQ: printType === "box" ? 1 : 0,
              w: printType === "box" ? neto : printEditingUnit?.unit,
              q:
                printType === "unit"
                  ? 1
                  : printType === "box"
                  ? pack
                  : printEditingUnit?.unit,
            };

      const hit = {
        algoliaUpdated: false,
        data: {
          qrData,
          printEditingUnit: editingUnit,
          product: productDTO,
          e: moment(e, "DD-MM-YYYY").format("DD/MM/YYYY"),
        },
        binData: {
          q,
          e: e ?? false,
          w,
          lot,
          binId,
          printType,
        },
        label: "6c296392f032441ba76f0e99cff9653b",
        printer: "7160b677ab00435184ca987625f5742b",
        status: "pending",
      };
      await newDoc({ indexName: "prints", hit });
    };

    const printByUnit = async () => {
      const totalBoxQ = parseInt(q / pack, 10);
      const totalGrossWeight =
        printType === "unit"
          ? product.grossWeight / 1000
          : (product.grossWeight * pack) / 1000;

      const editingUnit =
        printType === "total"
          ? {
              isBox: true,
              boxQ: totalBoxQ,
              w: (product.grossWeight * q) / 1000,
              q,
            }
          : {
              isBox: printType === "box",
              boxQ: printType === "box" ? 1 : 0,
              w: totalGrossWeight,
              q: printType === "unit" ? 1 : pack,
            };

      const hit = {
        algoliaUpdated: false,
        data: {
          qrData,
          printEditingUnit: editingUnit,
          product: productDTO,
          e: moment(e, "YYYY-MM-DD").format("DD/MM/YYYY"),
        },
        binData: {
          q,
          e: e ?? false,
          w: totalGrossWeight,
          lot,
          binId,
          printType,
        },
        label: "6c296392f032441ba76f0e99cff9653b",
        printer: "7160b677ab00435184ca987625f5742b",
        status: "pending",
        sent: FieldValue.serverTimestamp(),
        sent_timestamps: moment().unix(),
      };

      const batch = db.batch();
      const ref = db.collection("prints");

      if (printType !== "total") {
        [...Array(round(+printEditingUnit?.unit / 2))]?.forEach(async () => {
          const docRef = ref.doc();
          batch.set(docRef, hit);
        });
        await batch.commit();
        return;
      }

      await newDoc({ indexName: "prints", hit });
    };

    isWeightProduct ? await printByWeight() : await printByUnit();

    handleChangeUnit(null);
    setDebounce((prev) => !prev);
  };

  return (
    <Fade appear={false} in={open}>
      <Paper
        role="dialog"
        aria-modal="false"
        aria-label="Cookie banner"
        square
        variant="outlined"
        tabIndex={-1}
        elevation={1}
        sx={{
          position: "fixed",
          top: "30%",
          left: "50%",
          width: 320,
          height: 300,
          borderRadius: 6,
          transform: "translateX(-50%)",
          m: 0,
          p: 2,
          zIndex: 999,
          borderWidth: 0,
        }}
      >
        <DialogTitle>
          <IconButton
            sx={{ position: "absolute", right: 4, top: 2 }}
            onClick={closeDialog}
          >
            <CloseOutlined />
          </IconButton>
          <Stack>
            <Stack flexDirection="row" gap={4}>
              <Avatar size="small" src={product?.image} />
              <Typography sx={{ fontWeight: "bold", margin: 0 }}>
                {product?.name}
              </Typography>
              <Typography sx={{ fontWeight: "bold", margin: 0 }}>
                {" "}
                |{" "}
              </Typography>
              <Typography>
                {totalUnit}
                {isWeightProduct ? unit : ""}
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: 12 }}>{e}</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          {printed && (
            <Stack direction={"row"} gap={2}>
              <LocalPrintshop />
              <Typography sx={{ fontSize: 12 }}>{printed}</Typography>
            </Stack>
          )}
          {printType === "total" ? (
            <Typography>
              Imprimir una etiqueta para {total} {unit}
            </Typography>
          ) : (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={openKeyboard}
            >
              {numberWithCommas(printEditingUnit?.unit || "0")}{" "}
              {isWeightProduct ? "KG" : ""}
            </Button>
          )}
          {printType === "box" && neto > 0 && !isDisabled && !isAllPrinted && (
            <Typography sx={{ fontSize: 12, marginTop: 2 }}>
              Se va a imprimir una etiqueta con peso neto de {neto} Kg.
            </Typography>
          )}
          {!!marginStatus?.length && (
            <Stack sx={{ marginTop: 2 }} direction="row" gap={2}>
              <ErrorIcon style={{ color: "#f97316" }}></ErrorIcon>
              <Typography
                sx={{ fontSize: 12 }}
                color="#f97316"
                fontWeight={500}
              >
                {marginStatus}
              </Typography>
            </Stack>
          )}
          <Button
            disabled={isDisabled}
            sx={{ marginTop: 1 }}
            fullWidth
            color="primary"
            variant="outlined"
            onClick={print}
          >
            Imprimir
          </Button>
        </DialogContent>
      </Paper>
    </Fade>
  );
};
