/* eslint-disable */
import React, { useEffect, useMemo, useState, lazy, Suspense } from "react";
// components
import PageTitle from "../../components/PageTitle";
// data
import { Redirect,NavLink } from "react-router-dom"; 
import { IconButton, Skeleton } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useAreaDataContext } from "../../context/AreaContext";
import { useRoleDataContext } from "../../context/RoleContext";
import CustomFieldRender, {
  customFieldRender,
} from "../../components/CustomField";
import ButtonAddNew from "../app/cards/ButtonAddNew";
import { Box, Button, Checkbox, MenuItem, Select } from "@material-ui/core";
import queryString from "qs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../components/Utils/NumberWithCommas";
import FieldRender from "../../components/CardFirestore/DataCard/components/FieldRender";
import { Stack } from "@mui/material";
import { useLayoutState } from "../../context/LayoutContext";
import excludeVariablesFromRoot from "@mui/material/styles/excludeVariablesFromRoot";

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 500);
  }).then(() => promise);
}

const TableLazy = lazy(() => delayForDemo(import('../banks/consolidations/components/Table/Table')))
const InstantSearchTableLazy = lazy(() => delayForDemo(import('../../components/InstantSearch')));
export default function Tables({ index = "orders", style = {} }) {

  const areaData = useAreaDataContext();
  const layoutState = useLayoutState();
  const indexData = areaData.panel[index];

  const table = useMemo(() => index && <IndexTable index={index} />, [index]);
  return (
    <div {...{ style }}
    style={{ flexGrow: "1", minWidth:0, flexDirection: "column" }}>
      <Box style={{ display: "flex", alignItems: "center" }}>
      </Box>
      {indexData && table}
    </div>
  );
}

function makeFilterFromField(fields){
  const alwaysActived = fields.find(e => Object.hasOwn(e, 'options') && Object.hasOwn(e?.options, 'defaultFilter') && e?.options?.type === "date")
  const start = alwaysActived?.options?.defaultFilter?.start
  const end = alwaysActived?.options?.defaultFilter?.end
  const makeFilterFromField = alwaysActived?.options?.type === "date" ? 
  {
    [`${alwaysActived.field}_timestamp`]: {
      $gt: moment().add(start[0], start[1])?.unix(),
      $lt: moment().add(end[0], end[1])?.unix()
    }
  } : null
  return makeFilterFromField
}

export function IndexTable({ index = "", filters = "", hiddenFields = [] }) {
  const areaData = useAreaDataContext();
  const [tableToShow, setTableToShow] = useState(localStorage.getItem('tableToRender') || 'new')
  let indexData = areaData.panel[index];
  const defaultDateFilters = makeFilterFromField(indexData.fields)

  const roleData = useRoleDataContext();
  let tableFilters = {};

  CustomFieldRender({}, indexData.filters || "")
    .split(" AND ")
    .forEach((f) => (tableFilters[f.split(":")[0]] = f.split(":")[1]));

  let columns;
  if (filters) {
    filters = CustomFieldRender({}, filters);
    columns = JSON.parse(JSON.stringify(indexData.fields));
    let f = filters.split(" AND ");
    f.forEach((f) => (tableFilters[f.split(":")[0]] = f.split(":")[1]));
    for (let i in f) {
      let c = columns.find((a) => a.field === f[i].split(":")[0]);
      if (c) {
        c.options || (c.options = {});
        c.options.display = false;
      }
    }
  }

  if (hiddenFields) {
    columns = columns || JSON.parse(JSON.stringify(indexData.fields));
    for (let i in hiddenFields) {
      let c = columns.find((a) => a.field === hiddenFields[i]);
      if (c) {
        c.options || (c.options = {});
        c.options.display = false;
      }
    }
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let active = true;
    setLoading(true);
    setTimeout(() => active && setLoading(false), 50);
    return () => (active = false);
  }, [index, areaData && areaData.objectID, filters]);

  

  let formattedColumns = columns.map((a) => ({
    field: a?.field,
    headerName: a.name,
    options: a?.options,
    ...(a?.options?.type === "number"
      ? {
        type: "number",
        groupable: false,
        valueFormatter: ({ value }) => !isNaN(value) && (
          a.customField
        ? customFieldRender({ [a.field]: value }, a.customField)
        : numberWithCommas(value, a.options?.decimals || 0)
      ),
      }
      : {
        groupable: false,
        renderCell: (params) => (
          <>
              <FieldRender f={a} hit={params.row} index={index} />
          </>
        ),
      }),
    }))

    formattedColumns  = [
      {
        field: 'show',
        headerName: 'Ver',
        renderCell: (value) => (
          <NavLink to={`/app/${index}/${value?.row?.objectID}`}>
            <IconButton color="primary">
              <RemoveRedEyeIcon />
            </IconButton>
          </NavLink>
          // <Redirect push >

          // </Redirect>
        )},
      ...formattedColumns
    ]

  const history = useHistory();
  const parsedHash = queryString.parse(
    history.location.search?.replace("?", ""),
  );
  const [date] = useState([
    moment(parsedHash.from || "2024-01-01"),
    parsedHash.to ? moment(parsedHash.to) : moment().startOf("day"),
  ]);
  const dateFilter = {
    $gte: date[0]?.clone().unix(),
    $lt: date[1]?.clone().add(1, "day").unix(),
  };
  const handleChange = (event) => {
    localStorage.setItem("tableToRender", event.target.value);
    setTableToShow(event.target.value);
  };
  return (
    <>
    <Stack alignItems='center' direction='row'>
        {indexData?.title && (<PageTitle title={indexData?.title} />)}
        {indexData && <ButtonAddNew index={index} />}
      </Stack>
      <Select
        style={{marginBottom: 24}}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={tableToShow}
        label="Tabla"
        onChange={handleChange}
  >
    <MenuItem value={'new'}>Tabla 2.0</MenuItem>
    <MenuItem value={'old'}>Tabla 1.0</MenuItem>
  </Select>
      {tableToShow === 'old' && <Suspense fallback={<Skeleton
        variant="rectangular"
        width={'100%'}
        minWidth={0}
        height={'700px'}
      />}>
        {!loading && (
        <InstantSearchTableLazy
          index={indexData.collection || index}
          columns={columns || indexData.fields}
          addNew={roleData.new && roleData.new.includes(index)}
          filters={Object.keys(tableFilters)
            .filter((f) => f)
            .map((f) => f + ":" + tableFilters[f])
            .join(" AND ")}
          renderExpandableRow={(hit) => (
            <Redirect
              push
              to={{
                pathname:
                  "/app/" +
                  (indexData.collection || index) +
                  "/" +
                  hit.objectID,
                state: {
                  from: window.location.pathname,
                },
              }}
            />
          )}
        />
      )}
        </Suspense>}
    {tableToShow === 'new' && <Suspense fallback={<Skeleton
        variant="rectangular"
        width={'100%'}
        minWidth={0}
        height={'700px'}
      />}>

    <Stack gap={2} height="700px" flexGrow={1}>
       {!loading && (
          <TableLazy
            minWidth="100%"
            height="700px"
            index={indexData?.collection || index}
            columns={formattedColumns}
            defaultFilters={
              {
                [`${filters?.split(":")?.at(0)}`]: filters?.split(":")?.at(1),
                ...defaultDateFilters
            }
            }
            defaultSort={indexData?.defaultSort || {timestamp_timestamp: -1}}
            sx={{
              ".MuiDataGrid-main": {
                marginTop: 2,
              },
              ".MuiBox-root": {
                flexDirection: "row-reverse",
                padding: 0,
              },
              ".MuiDataGrid-toolbarContainer": {
                padding: 0,
              },
              ".MuiGrid-root": {
                maxWidth: "100%",
              },
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#e5e7eb",
              },
            }}
          />
       )}
     </Stack>
     </Suspense>}

    </>
  );
}