import { Typography, Stack } from "@mui/material";
import Table from "../../../banks/consolidations/components/Table/Table";
import Button from "@mui/material/Button";
import { useSearchParams } from "../../hooks/useSearchParams";
import { NavLink } from "react-router-dom";
import { formatUnix } from "../../utils/formatUnix";
import { MainLayout } from "../../layouts/MainLayout";
import { useContext } from "react";
import { ProviderContext } from "../../context/providerContext";
const SHIPTMENT_COLUMNS = [
  {
    field: "number",
    headerName: "Numero",
    flex: 1,
  },
  {
    field: "name",
    flex: 1,
    headerName: "Titulo",
    options: {
      quickFilter: true,
    },
  },
  {
    flex: 1,
    field: "units",
    headerName: "Unidades",
  },

  {
    flex: 1,
    field: "reservedDateDelivery",
    headerName: "Turno reservado",
    options: {},
    renderCell: (data) => {
      return (
        <>
          {data.row.reservedDateDelivery ? (
            <Typography fontSize={14}>
              {formatUnix(data.row.reservedDateDelivery, "DD/MM/YYYY HH:mm")}
            </Typography>
          ) : (
            ""
          )}
        </>
      );
    },
  },

  {
    flex: 1,
    field: "received.t",
    headerName: "Fecha de recepción",
    options: {},
    renderCell: (data) => {
      return (
        <>
          {data.row.received.t ? (
            <Typography fontSize={14}>
              {formatUnix(data.row.received.t, "DD/MM/YYYY HH:mm")}
            </Typography>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    flex: 1,
    field: "date_timestamp",
    headerName: "Fecha de creación",
    options: {},
    renderCell: (data) => {
      return (
        <>
          <Typography fontSize={14}>
            {formatUnix(data.row.date_timestamp, "DD/MM/YYYY HH:mm")}
          </Typography>
        </>
      );
    },
  },
  {
    flex: 1,
    field: "state",
    headerName: "Estado",

    options: {
      filter: true,
    },
  },
  {
    flex: 1,
    field: "action",
    headerName: "",
    sortable: false,
    renderCell: (data) => {
      const { getParam } = useSearchParams();
      const seller = getParam("seller");
      return (
        <>
          <NavLink
            to={
              seller
                ? `/providers/purchaseOrders/${data.row.name}?id=${data.id}&provider=${seller}`
                : `/providers/purchaseOrders/${data.row.name}?id=${data.id}`
            }
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3b82f6", fontSize: 10 }}
            >
              Ver detalles
            </Button>
          </NavLink>
        </>
      );
    },
  },
];
export default function ShipmentManagment() {
  const { getParam } = useSearchParams();
  const seller = getParam("seller");
  const { providerSelected,defaultProviders } = useContext(ProviderContext);
  const providerCompany = getParam("providerCompany");

  return (
    <>
      <MainLayout>
        <Stack gap={2}>
          <Typography
            component="h3"
            style={{ fontWeight: 700, fontSize: "24px", color: "#1e293b" }}
          >
            Gestión de envíos
          </Typography>
          <Table
            limit={20}
            style={{ borderColor: "transparent" }}
            minWidth="100%"
            height="700px"
            defaultFilters={{
              seller: seller,
              ...(!providerCompany && {provider:{ $in: defaultProviders}}),
              ...(providerCompany && {provider:providerSelected?.value} ),
              
            }}
            sx={{
              ".MuiDataGrid-main": {
                marginTop: 2,
              },
              ".MuiBox-root": {
                flexDirection: "row-reverse",
                padding: 0,
              },
              ".MuiDataGrid-toolbarContainer": {
                padding: 0,
              },
              ".MuiGrid-root": {
                maxWidth: "100%",
              },
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#e5e7eb",
              },
            }}
            index="purchaseOrders"
            // defaultFilters={{ state: "" }}
            defaultSort={{ number: -1 }}
            columns={SHIPTMENT_COLUMNS}
          />
        </Stack>
      </MainLayout>
    </>
  );
}
