import axios from "axios";
import { useQuery } from "react-query";
import _ from "lodash";

export function useMongoAggregate({
  index,
  aggregate,
  enabled = true,
  debounce,
  ...props
}) {
  const payload = JSON.stringify({ index, aggregate });

  const config = {
    method: "post",
    url: "https://us-central1-vivet-web.cloudfunctions.net/mongoAggregate",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };

  const info = useQuery(
    [debounce, payload],
    ({ signal }) => axios(config, { signal }),
    {
      keepPreviousData: true,
      staleTime: 5000,
      enabled,
      ...props,
    },
  );
  return [
    info?.data?.data,
    info?.isLoading,
    info?.error,
    _.omit(info, ["data", "isLoading", "error"]),
  ];
}
