import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import moment from "moment/moment";
import { MuiDateRangePicker } from "../../../utils/MuiCalendar";
import Table from "./components/Table/Table";
import { useHistory } from "react-router-dom";
import queryString from "qs";
import _ from "lodash";
import {
  columnsChecks,
  columnsInvoices,
  columnsNetsuite,
  columnsTransactions,
} from "./Commons";
import { useBanksDataContext } from "../BanksContext";
import TransactionDetailEdit from "./components/transactions/TransactionDetailEdit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Entities_balance from "../entities/EntityBalanceTable";

const saveText = ({ history, query, parsedHash }) => {
  history.replace({
    search: queryString.stringify(
      _.omitBy({ ...parsedHash, ...query }, (a) => !a),
    ),
  });
};

const defaultTransactionsWidth = 600;
const minDrawerWidth = 400;
const maxDrawerWidth = 2000;

export default function Consolidations() {
  const history = useHistory();
  const parsedHash = queryString.parse(
    history.location.search?.replace("?", ""),
  );

  const [date, setDate] = useState([
    moment(parsedHash.from || "2022-01-01"),
    parsedHash.to ? moment(parsedHash.to) : moment().startOf("day"),
  ]);

  const dateFilter = {
    $gte: date[0]?.clone().format("YYYY-MM-DD"),
    $lt: date[1]?.clone().add(1, "day").format("YYYY-MM-DD"),
  };

  useEffect(() => {
    saveText({
      history,
      parsedHash,
      query: {
        from: date[0]?.format("YYYY-MM-DD"),
        to: date[1]?.format("YYYY-MM-DD"),
      },
    });
  }, [date]);

  const { data, setData } = useBanksDataContext();

  const default_accounts_filter = data.selectedAccounts?.length
    ? { "accounts.k": { $in: data.selectedAccounts } }
    : {};

  const [drawerWidth, setDrawerWidth] = React.useState(
    localStorage.getItem("transactionsWidth") || defaultTransactionsWidth,
  );

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback((e) => {
    const newWidth = e.clientX - document.body.offsetLeft;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
      localStorage.setItem("transactionsWidth", newWidth);
    }
  }, []);

  return (
    <Grid container p={2}>
      <Grid item xs={"auto"}>
        <Grid container style={{ width: drawerWidth, position: "relative" }}>
          <div style={drawableGridStyle} onMouseDown={handleMouseDown} />
          <Grid item xs={12} p={2}>
            <MuiDateRangePicker
              localeText={{ start: "Desde", end: "Hasta" }}
              onChange={setDate}
              value={date}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Accordion
                sx={{ marginY: 3, marginX: 1 }}
                defaultExpanded={false}
                TransitionProps={{ mountOnEnter: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant={"h3"} sx={{ fontSize: "2rem" }}>
                    Cuentas Corrientes
                  </Typography>
                </AccordionSummary>
                <Entities_balance />
              </Accordion>
              {/* <Accordion */}
              {/*   sx={{ marginY: 3, marginX: 1 }} */}
              {/*   defaultExpanded={false} */}
              {/*   TransitionProps={{ mountOnEnter: true }} */}
              {/* > */}
              {/*   <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
              {/*     <Typography variant={"h3"} sx={{ fontSize: "2rem" }}> */}
              {/*       Diario */}
              {/*     </Typography> */}
              {/*   </AccordionSummary> */}
              {/*   <DiarioTable /> */}
              {/* </Accordion> */}
              {/* <Accordion sx={{ marginY: 3, marginX: 1 }} defaultExpanded={true}> */}
              {/*   <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
              {/*     <Typography variant={"h3"} sx={{ fontSize: "2rem" }}> */}
              {/*       Libro Mayor */}
              {/*     </Typography> */}
              {/*   </AccordionSummary> */}
              {/*   {data?.selectedAccounts ? <LibroTable /> : null} */}
              {/* </Accordion> */}
              <Accordion
                sx={{ marginY: 3, marginX: 1 }}
                defaultExpanded={false}
                TransitionProps={{ mountOnEnter: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant={"h3"} sx={{ fontSize: "2rem" }}>
                    Transacciones
                  </Typography>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setData({
                        idSelected: false,
                        isCreateAsiento: true,
                      });
                    }}
                  >
                    Agregar manual
                  </Button>
                </AccordionSummary>

                <AccordionDetails>
                  <Table
                    index={"transactions"}
                    columns={columnsTransactions}
                    limit={200}
                    defaultFilters={{
                      date: dateFilter,
                      ...default_accounts_filter,
                    }}
                    defaultSort={{
                      date: -1,
                      i: -1,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ marginY: 3, marginX: 1 }}
                defaultExpanded={false}
                TransitionProps={{ mountOnEnter: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant={"h3"} sx={{ fontSize: "2rem" }}>
                    Facturas
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table
                    index={"invoices"}
                    columns={columnsInvoices}
                    defaultFilters={{
                      date: dateFilter,
                      ...default_accounts_filter,
                    }}
                    defaultSort={{
                      date: -1,
                      ref: -1,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ marginY: 3, marginX: 1 }}
                defaultExpanded={false}
                TransitionProps={{ mountOnEnter: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant={"h3"} sx={{ fontSize: "2rem" }}>
                    Cheques
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table
                    index={"checks"}
                    columns={columnsChecks}
                    defaultFilters={{
                      "dates.issue": dateFilter,
                      ...default_accounts_filter,
                    }}
                    defaultSort={{
                      check_number: -1,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ marginY: 3, marginX: 1 }}
                TransitionProps={{ mountOnEnter: true }}
              >
                <AccordionSummary>
                  <Typography variant={"h3"} sx={{ fontSize: "2rem" }}>
                    Netsuite Cobranzas
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table
                    defaultFilters={{
                      date: dateFilter,
                      ...default_accounts_filter,
                    }}
                    index={"cobranzas_netsuite"}
                    columns={columnsNetsuite}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs style={{ width: 1 }}>
        {/* <Accounts contextData={data} dateFilter={dateFilter} /> */}
        {data?.idSelected || data.isCreateAsiento ? (
          <TransactionDetailEdit
            isCreateAsiento={data?.isCreateAsiento}
            transactionId={data?.idSelected}
            tableType={data?.tableType}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

const drawableGridStyle = {
  width: "5px",
  cursor: "col-resize",
  padding: "4px 0 0",
  borderTop: "1px solid #ddd",
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: "#f4f7f9",
};
