/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import { QueryClient, QueryClientProvider } from "react-query";
import { useUserData } from "../context/UserContext";
import AddressModal from "./Address/AddressModal";
import StoresExcel from "../pages/tmp/StoresExcel";
import ProductsExcel from "../pages/productsExcel/ProductsExcel";

import AppLayout from "../pages/app";
import RoadmapBaskets from "../pages/roadmaps/loadInPalletsTrucks";
import Banks from "../pages/banks/Banks";
import Providers from "../pages/providers/page";

const LoginComponent = lazy(() => import("../pages/login"));

const AssemblyComponent2 = lazy(() => import("../pages/assembly/Assembly.v2"));
const AssemblyComponent = lazy(() => import("../pages/assembly/Assembly"));
const AssemblyItem = lazy(() => import("../pages/assembly/AssemblyCard"));

const ControlOrders = lazy(() =>
  import("../pages/controlOrders/controlOrders"),
);

const toDisarm = lazy(() => import("../pages/toDisarm/toDisarm"));

const RoadmapContainer = lazy(() =>
  import("../pages/roadmaps/RoadmapContainer"),
);

const DeliveryRouter = lazy(() => import("../pages/delivery"));

const BinsControl = lazy(() => import("../pages/binsControl"));

const ProductsControl = lazy(() => import("../pages/productsControl"));

const remitosPDF = lazy(() => import("../pages/remitosPDF"));

const dashboards = lazy(() => import("../pages/dashboards"));
const newHome = lazy(() => import("../pages/newHome/newHome"));
const editMarket = lazy(() => import("../pages/editMarket/editMarket"));
const weightControl = lazy(() =>
  import("../pages/weightControl/weightControl"),
);
const BinsInventory = lazy(() =>
  import("../pages/binsInventory/BinsInventory"),
);
const queryClient = new QueryClient();

export default function App() {
  // global
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/panel"
            // eslint-disable-next-line no-return-assign
            component={() => (window.location.href = "/panel")}
          />
          <PrivateRoute path="/selectAddress" component={AddressModal} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          {/* <PrivateRoute path="/home" component={Home} /> */}
          <PrivateRoute path="/armado/:orderID" LazyComponent={AssemblyItem} />
          <PrivateRoute path="/armado2" LazyComponent={AssemblyComponent2} />
          <PrivateRoute path="/armado" LazyComponent={AssemblyComponent} />
          <PrivateRoute path="/controlOrders" LazyComponent={ControlOrders} />
          <PrivateRoute path="/toDisarm/:page" LazyComponent={toDisarm} />
          <Route path={`/roadmaps/:roadmapID/loadBaskets/:place`}>
            <RoadmapBaskets />
          </Route>
          <PrivateRoute path="/roadmaps" LazyComponent={RoadmapContainer} />
          <PrivateRoute path="/delivery" LazyComponent={DeliveryRouter} />
          <PrivateRoute path="/binsCheck" LazyComponent={BinsControl} />
          <PrivateRoute path="/binsInventory" LazyComponent={BinsInventory} />
          <PrivateRoute path="/productsCheck" LazyComponent={ProductsControl} />
          <PrivateRoute path="/remitos/:id" LazyComponent={remitosPDF} />
          <PrivateRoute path="/dashboards" LazyComponent={dashboards} />
          <PrivateRoute path="/dashboards/deposito" LazyComponent={dashboards} />
          <PrivateRoute path="/home" LazyComponent={newHome} />
          <PrivateRoute path="/productsExcel" LazyComponent={ProductsExcel} />
          <PrivateRoute path="/storesExcel" LazyComponent={StoresExcel} />
          <PrivateRoute path="/editMarket" LazyComponent={editMarket} />
          <PrivateRoute path="/weightControl" LazyComponent={weightControl} />
          <PublicRoute path="/login" LazyComponent={LoginComponent} />
          <PrivateRoute path="/app" component={AppLayout} />
          <PrivateRoute path={"/banks"} component={Banks} />
          <PrivateRoute path={"/providers"} component={Providers} />
          <Route // component={Error}
            render={() => <Redirect to="/" />}
          />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );

  // #######################################################################
}

function PrivateRoute({ component, LazyComponent, ...rest }) {
  const { roles, phoneNumber, uid, isAnonymous } = useUserData();

  function LoadingMessage({ phoneNumber }) {
    return phoneNumber ? (
      "No esta autorizado"
    ) : (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        uid && !isAnonymous ? (
          roles &&
          (roles?.includes(rest.path.replace("/", "")) ||
            roles?.reduce(
              (p, a) =>
                p ||
                [
                  "admin",
                  "armado",
                  "logistic",
                  "conductor",
                  "comercial",
                  "cobranzas",
                  "externo",
                ].includes(a),
              false,
            )) ? (
            component ? (
              React.createElement(component, props)
            ) : (
              <Suspense fallback={<LoadingMessage />}>
                <LazyComponent {...props} />
              </Suspense>
            )
          ) : (
            <LoadingMessage phoneNumber={phoneNumber} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component, LazyComponent, ...rest }) {
  const { uid, isAnonymous } = useUserData();

  const LoadingMessage = () => "I'm loading...";

  return (
    <Route
      {...rest}
      render={(props) =>
        uid && !isAnonymous ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : component ? (
          React.createElement(component, props)
        ) : (
          <Suspense fallback={<LoadingMessage />}>
            <LazyComponent {...props} />
          </Suspense>
        )
      }
    />
  );
}
