import { Divider, Stack, Typography } from '@mui/material';
import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

export default function ReserveDateModal({ onChangeCallback = () => null, error, buttonAccept, buttonCancel, defaultStaticPickerValue, headerLabel = "Reservar un turno de entrega", onAcceptDate,modalButton, avlScheduleDate }) {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultStaticPickerValue ? defaultStaticPickerValue : false)

  // Drawer display func
  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  const resetSelectedDate = () => {
    setSelectedIndex(null)
    setSelectedDate(false)
  }
  
  // Date handling func
  const handleChange = (evt) => {
    const filteredDate = avlScheduleDate?.filter(e => moment(moment.unix(e.day).format("DD/MM/YYYY"), "DD/MM/YYYY").unix() === moment(evt.format("DD/MM/YYYY"), "DD/MM/YYYY").unix() )[0]
    setSelectedDate([{day: filteredDate.day}])
    onChangeCallback()
  }

  const handleDisabledDate = (day) => {
    let shouldDisable = true;
    !avlScheduleDate?.forEach(date => {
      if(date.day === moment(day.format("DD/MM/YYYY"), "DD/MM/YYYYY").unix()){
        shouldDisable = false;
      }
      shouldDisable
    })
    return shouldDisable
  };
  const allHoursFromDay = selectedDate && avlScheduleDate.filter(d => d.day === selectedDate[0].day);
  const isSelectedDateNotComplete = selectedDate ? !(Object.hasOwn(selectedDate[0], "day") && Object.hasOwn(selectedDate[0], "hours") && Object.hasOwn(selectedDate[0], "ts")) : true;

  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleMenuItemClick = (event, index) => {
    setSelectedDate([event])
    setSelectedIndex(index);
  };
  // eslint-disable-next-line react/display-name
  const ModalHandler = () => modalButton(handleClickOpen)
  const ButtonCancel = () => buttonCancel({ handleClose, resetSelectedDate, selectedDate })
  const ButtonAccept = () => buttonAccept({ handleClose, isSelectedDateNotComplete, resetSelectedDate, selectedDate, onAcceptDate })
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ModalHandler />
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {headerLabel}
        </DialogTitle>
        <DialogContent>
        <Stack direction="row">
            <StaticDatePicker
              disablePast
              defaultValue={defaultStaticPickerValue && moment.unix(defaultStaticPickerValue[0].ts)}
              shouldDisableDate={handleDisabledDate} 
              slotProps={{ actionBar: { actions: [] } }} 
              onChange={handleChange} 
              localeText="Elige una fecha entre las disponibles" >
            </StaticDatePicker>
            <Divider orientation='vertical' flexItem style={{marginInline: 16}}/>
            <Stack gap={1} marginTop={"24px"}>
                <Typography variant='h4' style={{
                  textTransform:"uppercase", 
                  letterSpacing: "0.08333em", 
                  fontFamily:'"Roboto","Helvetica","Arial","sans-serif"', 
                  fontSize: "0.75rem",
                  color: "rgba(0, 0, 0, 0.6)"}}>Horarios disponibles</Typography>
 
                {selectedDate && <MenuList>
                    {allHoursFromDay && allHoursFromDay?.map((e,index) => <MenuItem sx={{"&.Mui-selected": {
                      "&:hover": {
                        backgroundColor: "#60a5fa",      
                      },
                  backgroundColor: "#60a5fa",
                  color:"white"
                }}}
                      onClick={() => {
                        handleMenuItemClick(e, index)
                        onChangeCallback()
                      }}
                      selected={defaultStaticPickerValue ? true : index === selectedIndex}
                      key={moment.unix(e.hours).format("HH:mm a")}>{moment.unix(e.hours).format("HH:mm a")}</MenuItem>)}
                </MenuList>}

                {/* -- Used as preview of hours -- */}
                  {!selectedDate && <DigitalClock 
                      timeStep={30}
                      skipDisabled 
                      disabled={!selectedDate} 
                      sx={{"&.MuiDigitalClock-root":{
                        minHeight: "100%"
                      }}}/>}
            </Stack>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="column">
            <Typography color='red'>
              {console.log('inside choosereserv: ', error)}
              {error?.error && error?.message}
            </Typography>
            <Stack direction="row">
              <ButtonCancel />
              <ButtonAccept />
            </Stack>
          </Stack> 
        </DialogActions>
      </Dialog>
      </LocalizationProvider>
  );
}