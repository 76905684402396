/* eslint-disable react/prop-types */
import { Grid } from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router-dom";
import InstantSearchTable from "../InstantSearch";
import CustomFieldRender from "../CustomField";
import customField from "../CustomField";
import { useUserData } from "../../context/UserContext";
import { IndexTable } from "../../pages/tables";

export default function CardElement({ roleData, hit, properties }) {
  const user = useUserData();

  let grid = {
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12,
  };
  if (properties.grid) grid = { ...grid, ...properties.grid };
  console.log({hit,roleData,properties})
  return (
    <Grid item lg={grid.lg} md={grid.md} sm={grid.sm} xs={grid.xs}>
      {" "}
      {properties.type === "table" && (
        <InstantSearchTable
          title={properties.table.title}
          index={properties.table.index}
          columns={properties.table.fields}
          addNew={roleData.new && roleData.new.includes(properties.table.index)}
          filters={
            properties.table.filters
              ? CustomFieldRender({ ...hit, user }, properties.table.filters)
              : ""
          }
          renderExpandableRow={
            properties.table.openOnClick &&
            ((h) => (
              <Redirect
                push
                to={{
                  pathname: `/app/${properties.table.index}/${h.objectID}`,
                  state: {
                    from: window.location.pathname,
                  },
                }}
              />
            ))
          }
        />
      )}
      {properties.type === "indextable" && (
        <IndexTable
          tableTitle={properties.table.tableTitle}
          index={properties.table.index}
          filters={customField(hit, properties.table.filters)}
          hiddenFields={properties.table.hiddenFields}
        />
      )}
    </Grid>
  );
}
