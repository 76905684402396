import { Route, Switch, Redirect } from "react-router-dom";
import ProvidersRootLayout from "./layouts/ProvidersLayout";
import ProvidersProducts from "./sections/Products";
import ProvidersHome from "./sections/Providers";
import Brands from "./sections/Brands";
import ShipmentManagment from "./sections/ShipmentManagment/ShippingManagement";
import SellerProvider from "./context/sellerContext";
import ProviderFNProvider from "./context/providerContext";
import ProductsControlSearchBox from "../productsControl/listProducts/productsControlSearchBox";
import ProductCard from "../productsControl/productCard/ProductCard";
import { Breadcrumb } from "./components/breadcrumb";
import { useSearchParams } from "./hooks/useSearchParams";
import { ProductsControlSearchBoxFilters } from "./constants/ProductsControlSearchBoxFilters";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import OrderShipmentContainer from "./sections/ShipmentManagment/[orderContainer]";
import { useUserData } from "../../context/UserContext";
import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";
import ParamPersistance from "./components/ParamPersistance";
import Campaigns from "./sections/Campaigns/Campaigns";
import Campaign from "./sections/Campaigns/[campaign]";

const RoutesList = ({routes, userAuth}) => {

return <>
    {routes.map((rou) => (
      <>
        {(Object.hasOwn(rou, "children")) ? (
          <Switch>
            {rou.children.map((route) => (
                <PrivateUserRoute
                key={route.path}
                  isAuthenticated={
                    userAuth.length <= 0
                      ? false
                      : userAuth[0].includesUser
                  }
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
          ))}
        </Switch>) : 
        (
            <PrivateUserRoute
              isAuthenticated={userAuth.length <= 0 ? false : userAuth[0].includesUser}
              path={rou.path}
              exact={rou.exact}
              component={rou.component}/>)}  
      </>
    ))}
</>}

function ProductsControlSearchBoxStyled() {
  const { getParam } = useSearchParams();
  const location = useLocation();
  const history = useHistory();
  const seller = getParam("seller");

  useEffect(() => {
    if (seller) {
      // deleteParam('providerCompany')
      return history.push(
        `/providers/productsCheck?facetFilters%5Bseller%5D=${seller}&provider=${seller}`,
      );
    }
    history.push(`/providers/productsCheck`);
  }, [location.pathname]);
  return (
    <div style={{ position: "relative", top: 56.95 }}>
      <ProductsControlSearchBox
        defaultFacetFilters={`"seller":"${seller}"`}
        filters={ProductsControlSearchBoxFilters}
        styles={{ left: 40 }}
      />
    </div>
  );
}
function ProductCardStyled({ ...rest }) {
  return (
    <div style={{ marginTop: 56.5, zIndex: 0 }}>
      <ProductCard {...rest} />
    </div>
  );
}
const routes = [
  {
    key: Date.now(),
    path: "/providers/campaigns",
    exact: true,
    name: ["Proveedores", "Campañas"],
    children: [
      {
        name: ["Proveedores", "Campañas"],
        path: "/providers/campaigns",
        exact: true,
        dynamic: true,
        component: Campaigns,
      },
      {
        name: ["Proveedores", "Campañas", ":id"],
        path: "/providers/campaigns/:id",
        dynamic: true,
        exact: true,
        component: Campaign,
      },
    ],
  },
  {
    key: Date.now(),
    path: "/providers/productsCheck",
    exact: true,
    children: [
      {
        name: ["Proveedores", "Chequeo de productos"],
        path: "/providers/productsCheck",
        exact: true,
        dynamic: true,
        component: ProductsControlSearchBoxStyled,
      },
      {
        path: "/providers/productsCheck/:id",
        name: ["Proveedores", "Chequeo de productos", ":id"],
        exact: true,
        dynamic: true,
        component: ProductCardStyled,
      },
    ],
  },
  {
    key: Date.now(),
    path: "/providers/purchaseOrders",
    exact: true,
    children: [
      {
        name: ["Proveedores", "Gestión de envios"],
        path: "/providers/purchaseOrders",
        exact: true,
        dynamic: true,
        component: ShipmentManagment,
      },
      {
        path: "/providers/purchaseOrders/:id",
        name: ["Proveedores", "Gestión de envios", ":id"],
        exact: true,
        component: OrderShipmentContainer,
      },
    ],
  },
  {
    key: Date.now(),
    path: "/providers/products",
    component: ProvidersProducts,
    exact: true,
    name: ["Proveedores", "Productos"],
  },
  {
    key: Date.now(),
    path: "/providers",
    component: ProvidersHome,
    exact: true,
    name: ["Proveedores"],
  },
  {
    key: Date.now(),
    path: "/providers/brands",
    component: Brands,
    exact: true,
    name: ["Proveedores", "Marcas"],
  },

];

export default function Providers() {
  const { uid } = useUserData();
  const [userAuth, userAuthLoading] = useMongoAggregate({
    index: "sellers",
    aggregate: [
      {
        $match: {
          "data.users": { $in: [uid] },
        },
      },
      {
        $project: {
          "data.objectID": 1,
          "data.users": 1,
        },
      },

      {
        $addFields: {
          includesUser: {
            $cond: [
              { $eq: ["$data.users", null] },
              false,
              { $in: [uid, "$data.users"] },
            ],
          },
        },
      },
    ],
  });

  if (userAuthLoading) return;
  return (
    <ProviderFNProvider>
      <SellerProvider>
        <ParamPersistance>
          <ProvidersRootLayout>
            <Breadcrumb routes={routes} />
            <RoutesList routes={routes} userAuth={userAuth}/>
          </ProvidersRootLayout>
        </ParamPersistance>
      </SellerProvider>
    </ProviderFNProvider>
  );
}

function PrivateUserRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
