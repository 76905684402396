import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateDoc } from "../../../../../firebase/database";

const MODEL = {
  title: "",
  details: "",
  view: [],
};

const OPTIONS = [
  { value: "picking", label: "Picking" },
  { value: "driver", label: "Chofer" },
  { value: "remito", label: "Remito" },
  { value: "roadmap", label: "Roadmap" },
];

export const EditObservations = ({
  observations,
  orderID,
  toggleVisibility,
}) => {
  const [newObservations, setNewObservations] = useState(
    observations || [{ ...MODEL }],
  );
  const addObservation = () => {
    setNewObservations((prev) => [...prev, { ...MODEL }]);
  };
  const deleteObservation = (index) => {
    const updatedObservations = newObservations.filter(
      (elemento, indice) => index !== indice,
    );
    setNewObservations(updatedObservations);
  };

  const saveChanges = async () => {
    await updateDoc({
      indexName: "orders",
      id: orderID,
      hit: { ["observations"]: newObservations },
    }).then(() => toggleVisibility());
  };



  return (
    <Box width="fit-content">
      <Typography sx={{ fontWeight: "bold" }}>Observaciones</Typography>
      <Stack direction="column" gap={2} flexWrap="wrap" marginTop={2}>
        {(newObservations || []).map((observation, index) => (
          <Stack direction="row" spacing={1} key={index}>
            <Stack alignItems="center" justifyContent="center">
              <DeleteIcon
                onClick={() => deleteObservation(index)}
                color="secondary"
              />
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Título</Typography>
              <TextField
                value={observation.title}
                onChange={(e) => {
                  const updatedObservations = [...newObservations];
                  updatedObservations[index].title = e.target.value;
                  setNewObservations(updatedObservations);
                }}
                label="titulo"
              />
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Detalle
              </Typography>
              <TextField
                label="Detalle"
                value={observation.details}
                onChange={(e) => {
                  const updatedObservations = [...newObservations];
                  updatedObservations[index].details = e.target.value;
                  setNewObservations(updatedObservations);
                }}
              />
            </Stack>
            <Stack direction="row" w="100%" spacing={1}>
              {OPTIONS.map((option) => (
                <Stack key={option}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {option.label}
                  </Typography>
                  <Checkbox
                    checked={newObservations[index]?.view?.includes(
                      option.value,
                    )}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      let updatedObservations = [...newObservations];
                      if (checked) {
                        let viewAdded = updatedObservations[index].view.concat(
                          option.value,
                        );
                        updatedObservations[index].view = viewAdded;
                      }
                      if (!checked) {
                        let viewFiltered = updatedObservations[
                          index
                        ].view.filter((op) => op !== option.value);
                        updatedObservations[index].view = viewFiltered;
                      }
                      setNewObservations(updatedObservations);
                    }}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {/* ITEMS */}

      <Button
        sx={{ marginY: 2, w: "100%" }}
        onClick={addObservation}
        fullWidth
        variant="outlined"
        color="primary"
      >
        agregar
      </Button>
      <Button
        onClick={() => saveChanges()}
        variant="contained"
        color="primary"
        fullWidth
      >
        CONFIRMAR
      </Button>
    </Box>
  );
};
