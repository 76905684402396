/* eslint-disable max-len */
/* eslint-disable no-unexpected-multiline */

/* eslint-disable no-nested-ternary */

/* eslint-disable react/prop-types */
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _ from "lodash";
import { updateDoc } from "../../../../../firebase/database";
import { Sf } from "../../../../../components/Widget/SnowFlakes";
import { AccordionHeader } from "./AccordionHeader";
import { ItemHeader } from "./ItemHeader";
import momentConverter from "../../../../../utils/momentConverter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Facturar from "../../invoice/invoice";
import {
  assemblyStates,
  assemblyStatesColors,
  basketStates,
  orderStates,
  orderStatesColors,
  shipmentStates,
  shipmentStatesColors,
} from "../../../../roadmaps/commons";
import palletImg from "../../../../../images/palletBox.jpg";
import { CarrierList } from "./carrierList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useModal } from "../../../utils/useModal";
import { GenericModal } from "../../../utils/GenericModal";
import { EditObservations } from "./EditObservations";
import AddressModal from "../../../../../components/Address/AddressModal";
import { ListPickedInfo } from "./ListPickedInfo";
import Remito from "./Remito";
import { useUserData } from "../../../../../context/UserContext";
import { useDocs } from "../../../../../algolia/getObjects";
import { useMongoAggregate } from "../../../../../utils/mongoAggregate/useMongoAggregate";
import { PaymentsCard } from "./paymentsCard";
import { paymentStates } from "../../../utils/commons";

export function Header({ order }) {
  const [editShipmentState, setEditShipmentState] = useState();
  const [editShippingDate, setEditShippingDate] = useState(false);
  const [editShipping, setEditShipping] = useState(false);
  const [newComment, setNewComment] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openObservations, toggleObservationsModal] = useModal();
  const [editPayment, setEditPayment] = useState(false);
  const { uid } = useUserData();

  const paymentOptions = useDocs({ index: "paymentOptions" });

  const userModifyPaymentMethod = useDocs({
    index: "users",
    ids: [order?.paymentChanged?.by],
  })?.[order?.paymentChanged?.by];

  const [prevRoadmaps] = useMongoAggregate({
    index: "roadmaps",
    aggregate: [
      { $match: { "data.objectID": { $in: order?.prevRoadmaps } } },
      {
        $project: {
          _id: 0,
          id: "$data.objectID",
          date: "$data.date",
          data: "$data.data",
        },
      },
    ],
  });

  const changePaymentMethod = async (e) => {
    const { value } = e.target;

    await updateDoc({
      indexName: "orders",
      id: order?.objectID,
      hit: {
        paymentMethod: value,
        paymentChanged: { t: (new Date() / 1000) | 0, by: uid },
      },
    });
    setEditPayment(false);
  };

  const shippings = useDocs({ index: "shippings" });
  const [carriers] = [];

  const updateShipment = (e) => {
    if (e.target.value) {
      updateDoc({
        indexName: "orders",
        id: order?.objectID,
        hit: { shipmentState: e.target.value },
      });
    }
    setEditShipmentState(false);
  };

  const updateShippingDate = (e) => {
    if (e.target.value) {
      updateDoc({
        indexName: "orders",
        id: order?.objectID,
        hit: { shippingDate: moment(e.target.value).format("YYYY-MM-DD") },
      });
    }
    setEditShippingDate(false);
  };

  const updateShipping = (e) => {
    if (e.target.value) {
      updateDoc({
        indexName: "orders",
        id: order?.objectID,
        hit: { shipping: e.target.value },
      });
    }
    setEditShipping(false);
  };

  const close = () => setOpenMenu(false); // close de menu vertical

  const boxes = Object.keys(order.shippingBoxes || {}).length;
  const pallets = Object.keys(order.pallets || {}).length;
  // eslint-disable-next-line max-len
  const baskets = Object.entries(order.baskets || {}).filter(
    ([b]) =>
      !_.isEmpty(
        _.pickBy(
          order.items,
          (p) => p.basket === b && _.isEmpty(p.shippingBox),
        ),
      ),
  );

  const saveComment = async (e) => {
    const { value } = e.target;

    if (!value?.length) {
      setNewComment(false);
    }

    if (value?.length) {
      updateDoc({
        indexName: "orders",
        id: order.objectID,
        hit: { comments: value },
      }).then(() => setNewComment(false));
    }
  };

  const AddNewComment = () => {
    if (order.comments && !newComment) {
      return null;
    }

    return (
      <>
        {!newComment ? (
          <Button
            sx={{ marginTop: 3 }}
            onClick={() => setNewComment(true)}
            color="primary"
            variant="contained"
            size="small"
          >
            Agregar comentario
          </Button>
        ) : (
          <TextField
            label="comentario"
            onBlur={saveComment}
            onKeyDown={(e) => (e.key === "Enter" ? saveComment(e) : null)}
          />
        )}
      </>
    );
  };

  const AddObservations = ({ observations }) => {
    return (
      <>
        <Button
          sx={{ marginTop: 3 }}
          onClick={() => toggleObservationsModal()}
          color="primary"
          variant="contained"
          size="small"
        >
          {observations ? "Editar observaciones" : "Agregar observaciones"}
        </Button>
      </>
    );
  };

  const gridItemsHeader = [
    ...(order?.user?.respondio_id
      ? [
          {
            children: (
              <Button
                // size="small"
                // label={orderStates?.[order?.status] ?? ""}
                // color={orderStatesColors[order?.status]}
                onClick={() =>
                  window
                    .open(
                      `https://app.respond.io/space/191392/inbox/${order?.user?.respondio_id}`,
                      "_blank",
                    )
                    .focus()
                }
              >
                <img
                  width={"30px"}
                  height={"30px"}
                  src={
                    "https://cdn.respond.io/platform/web/assets/static/images/square_color_logo.png"
                  }
                  alt={"respond"}
                />
              </Button>
            ),
          },
        ]
      : []),
    {
      children: (
        <Chip
          size="small"
          label={orderStates?.[order?.status] ?? ""}
          color={orderStatesColors[order?.status]}
        />
      ),
    },
    {
      headerLabel: "Metodo de pago",
      children: (
        <>
          {!editPayment ? (
            <>
              <Stack
                onClick={() => order.status !== "payed" && setEditPayment(true)}
                sx={order.status !== "payed" ? { cursor: "pointer" } : {}}
                direction={"row"}
              >
                <Typography>{order?.paymentMethod?.name}</Typography>
              </Stack>
              {order?.paymentChanged && (
                <Box>
                  <Chip
                    size="small"
                    color="primary"
                    label={userModifyPaymentMethod?.name}
                  />
                  <Typography>
                    {moment(order?.paymentChanged?.t * 1000).format("DD/MM")}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <>
              <FormControl fullWidth>
                <InputLabel id="demo-changePaymentMethod">
                  Método de pago
                </InputLabel>
                <Select
                  labelId="demo-changePaymentMethod"
                  id="changePaymentMethod"
                  value={order?.paymentMethod?.objectID}
                  label="Método de pago"
                  onChange={changePaymentMethod}
                >
                  {_.map(paymentOptions, ({ name: paymentMethod }, id) => (
                    <MenuItem key={paymentMethod} value={id}>
                      {paymentMethod}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </>
      ),
    },
    {
      headerLabel: "Lista de precios",
      label: order?.priceList?.name,
      link: `/app/priceList/${order?.priceList?.objectID}`,
    },
    {
      headerLabel: order?.mp_comission && "Comision MP",
      label: order?.mp_comission ?? "",
    },
    {
      children: (
        <Chip
          size="small"
          label={assemblyStates?.[order?.assemblyState] ?? ""}
          color={assemblyStatesColors[order?.assemblyState]}
        />
      ),
      headerLabel: "Estado de armado",
    },
    {
      children: (
        <>
          {!editShipmentState ? (
            <>
              <Chip
                size="small"
                label={
                  order?.takeaway
                    ? shipmentStates?.takeaway
                    : shipmentStates?.[order?.shipmentState] ?? ""
                }
                color={
                  order?.takeaway
                    ? shipmentStatesColors?.takeaway
                    : shipmentStatesColors[order?.shipmentState]
                }
              />
              <EditIcon
                onClick={() =>
                  !order?.roadmap?.objectID ? setEditShipmentState(true) : null
                }
              />
            </>
          ) : null}
          {editShipmentState ? (
            <Select defaultValue={order?.shipmentState} onBlur={updateShipment}>
              {Object.keys(shipmentStates || {})?.map((x, index) => (
                <MenuItem key={index} value={x}>
                  {shipmentStates?.[x] ?? ""}
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </>
      ),
      headerLabel: "Estado de envío",
    },
    {
      children: order.approved ? (
        <Typography>Aprobada</Typography>
      ) : (
        <>
          {" "}
          <Typography>Pendiente</Typography>
          <Button
            variant="outline"
            size="sm"
            onClick={() =>
              updateDoc("orders", order.objectID, { approved: true })
            }
          >
            Aceptar Pedido
          </Button>
        </>
      ),
      headerLabel: "Estado de la orden",
    },
    {
      children: <Facturar orderID={order.objectID} order={order} />,
      headerLabel:
        ["checked", "assembled", "inBoxes"].includes(order.assemblyState) &&
        "Facturación",
    },
    {
      children: <Remito />,
      headerLabel:
        ["checked", "assembled", "inBoxes"].includes(order.assemblyState) &&
        "Remito",
    },
    {
      children: (
        <Checkbox
          checked={order?.assembly}
          onChange={(e) =>
            updateDoc({
              indexName: "orders",
              id: order?.objectID,
              hit: {
                ["assembly"]: e.target.checked,
              },
            })
          }
        />
      ),
      headerLabel: "Disponible para armar",
    },
  ];

  const gridAccordionHeader = [
    {
      label: "Armado",
      icon: <AssignmentIcon />,
      gridXS: 4,
      children: (
        <>
          <ListPickedInfo order={order} />
          {!!Object.keys(order?.palletsStates || {})?.length && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  maxHeight: 180,
                  overflowY:
                    Object.keys(order?.palletsStates || {})?.length > 38 &&
                    "scroll",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: 300,
                    maxHeight: 180,
                    overflowY:
                      Object.keys(order?.palletsStates || {})?.length > 20
                        ? "scroll"
                        : "none",
                  }}
                >
                  {Object.keys(order.palletsStates || {})
                    .sort(
                      (a, b) =>
                        a.replace(/[^0-9.]/g, "") - b.replace(/[^0-9.]/g, ""),
                    )
                    .map((s) => (
                      <span key={s} style={{ margin: "0 .8rem" }}>
                        {" "}
                        <img
                          alt="palletImg"
                          width={30}
                          height={30}
                          src={palletImg}
                        />
                        <p style={{ display: "inline" }}>{s}</p>
                        <Sf
                          q={
                            s.charAt(0) === "C"
                              ? 2
                              : s.charAt(0) === "F"
                              ? 1
                              : 0
                          }
                        />
                      </span>
                    ))}
                </div>
              </Box>
            </div>
          )}
          {order?.assemblyState !== "inBoxes" &&
            !!Object.keys(order?.baskets || {}).length && (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {Object.keys(order?.baskets || {}).map((b) => (
                  <span key={b} style={{ margin: "0 .8rem" }}>
                    <FontAwesomeIcon icon={faShoppingBasket} />
                    <p style={{ display: "inline", marginLeft: 1 }}>{b}</p>
                    <Sf q={b.c} />
                  </span>
                ))}
              </Box>
            )}
          {!!Object.keys(order.assembledBy || {}).length && "Armado por : "}
          {Object.entries(order.assembledBy || {}).map(([, data], index) => (
            <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
              {data.name}
            </Box>
          ))}
          {momentConverter(
            order.assemblyStartTime_timestamp,
            order.lastAssemblyEdit_timestamp,
          )}
        </>
      ),
      infoExtra: (
        <Typography>
          {boxes
            ? `Cajas ${boxes} ${pallets ? `y Pallets ${pallets}` : ""} ${
                baskets.length ? `y Canastos ${baskets.length}` : ""
              }`
            : "Canastos"}
        </Typography>
      ),
    },
    {
      label: "Canastos",
      children: (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "bold", minWidth: 130 }}
                    component="th"
                    scope="row"
                  >
                    Canasto
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Estado
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Items
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(order.baskets || {}).map(
                  ([b, values], index) => {
                    const actualState = basketStates[values?.state] || "";
                    const items = values.q;
                    const actualBaskets =
                      values?.state === "divided"
                        ? _.chain(order["items"])
                            .pickBy((v) => v.oBasket === b)
                            .values((v) => v.basket)
                            .value()
                            .map((v) => v.basket)
                        : null;

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <FontAwesomeIcon icon={faShoppingBasket} />
                          {b} <Sf color={"blue"} q={values.c} />
                          {actualBaskets?.length ? (
                            <>
                              <ArrowDownwardIcon style={{ color: "green" }} />
                              <Grid container>
                                {_.uniq(actualBaskets).map((b, index) => (
                                  <Grid xs={12} key={index} item>
                                    <FontAwesomeIcon icon={faShoppingBasket} />
                                    {b}
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          ) : null}
                        </TableCell>
                        <TableCell align="right">{actualState}</TableCell>
                        <TableCell align="right">{items}</TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ),
    },
    {
      label: "Envío",
      icon: <LocalShippingIcon />,
      children: (
        <>
          <Stack direction="column">
            {order?.zippinShippingSelect && (
              <Stack
                direction="row"
                sx={{ marginTop: 2, marginBottom: 2, gap: 4 }}
              >
                {order?.zippinShippingSelect?.carrier?.logo ? (
                  <img
                    style={{ width: 50, objectFit: "contain" }}
                    src={order?.zippinShippingSelect?.carrier?.logo}
                    alt={order?.zippinShippingSelect?.carrier?.name}
                  />
                ) : (
                  <Typography>
                    {order?.zippinShippingSelect?.carrier?.name}
                  </Typography>
                )}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={order?.externalShipment?.tracking_url}
                >
                  <Button size="small" variant={"outlined"} color="primary">
                    Ver envío
                  </Button>
                </a>
              </Stack>
            )}

            <Typography component="span" sx={{ fontSize: "14px" }}>
              {order.data?.address?.formatted_address}
            </Typography>
            {Object.hasOwn(order?.data, "address") && (
              <>
                {Object.hasOwn(order?.data?.address, "details") &&
                  order?.data?.address?.details !== "" && (
                    <Box
                      sx={{
                        marginTop: "8px",
                        backgroundColor: "lightgray",
                        borderRadius: "4px",
                        padding: "2px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: 500,
                        }}
                      >
                        {order?.data?.address?.details}
                      </Typography>
                    </Box>
                  )}
              </>
            )}
            {order.shipmentState !== "delivered" && (
              <Box sx={{ marginTop: "16px", width: "100%" }}>
                <AddressModal
                  orderID={order?.objectID}
                  storeID={order?.store?.objectID}
                  userID={order?.user?.objectID}
                />
              </Box>
            )}
          </Stack>
          <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />

          {order?.shipping_receiver && (
            <Stack gap="4px">
              <Typography
                component="h4"
                sx={{ fontSize: "14px", fontWeight: 700 }}
              >
                Recibió
              </Typography>
              <Stack gap="4px">
                <Box disableGutters={true} component="div" disablePadding>
                  {order.shipping_receiver.name}
                </Box>
                <Box>
                  <Typography
                    component="strong"
                    sx={{ fontWeight: 700, fontSize: "14px" }}
                  >
                    DNI:{" "}
                    <Typography
                      component="strong"
                      sx={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      {order.shipping_receiver?.id}
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                    Relación:{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      {order.shipping_receiver.relation
                        .charAt(0)
                        .toUpperCase() +
                        order.shipping_receiver.relation.slice(1)}
                    </Typography>
                  </Typography>
                </Box>
                {order?.delivered_timestamp && (
                  <Stack direction="row">
                    <CalendarTodayIcon
                      sx={{ height: 14, minWidth: "fit-content" }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>
                      {moment(order.delivered_timestamp * 1000).format(
                        "DD/MM/YYYY HH:mm:a",
                      )}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          )}
          {order?.shipping?.name && (
            <div>
              <b>
                Reparto:
                {order.shipping?.name}
              </b>
            </div>
          )}
          <>
            {!order?.delivered_timestamp && !editShippingDate && (
              <span
                onClick={() => !order.roadmap && setEditShippingDate(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 2,
                  cursor: !order.roadmap ? "pointer" : "auto",
                }}
              >
                <CalendarTodayIcon sx={{ fontSize: "15px" }} />
                {order?.shippingDate &&
                  moment(order?.shippingDate).format("DD/MM/YYYY")}
              </span>
            )}

            {editShippingDate ? (
              <TextField
                onBlur={updateShippingDate}
                size="small"
                fullWidth
                autofocus
                defaultValue={order?.shippingDate}
                type="date"
              />
            ) : null}
          </>
          {order.roadmap && order.roadmap.status !== "pending" && (
            <>
              <h2>
                <b>Transporte: </b>
                <Link to={`/app/roadmaps/${order?.roadmap?.objectID}`}>
                  <IconButton>
                    <OpenInNewIcon />
                  </IconButton>
                </Link>
              </h2>
              {!!order.roadmap?.driver && (
                <Typography sx={{ display: "inline" }}>
                  Conductor:
                  {order?.roadmap?.data?.driver}
                </Typography>
              )}
              <Typography>
                {`${order?.roadmap?.data?.vehicleType} ${order.roadmap?.data?.vehicle?.manufacturer}` &&
                  order.roadmap?.data?.vehicle?.manufacturer}
              </Typography>
              {!!Object.keys(order?.ETD || {}).length && (
                <Typography>Horario estimado de entrega:</Typography>
              )}
              {!!Object.keys(order?.ETD || {}).length &&
                order?.ETD?.map((e, index) => (
                  <Typography
                    key={index}
                    sx={{ display: "inline", margin: "0 .2rem" }}
                  >
                    {index !== 0 && "-"}
                    {e}
                  </Typography>
                ))}
              {order.remark && `comentario del chofer :${order.remark}`}
            </>
          )}
          {
            ["congelado", "frio"][
              { name: "prueba1", conservations: ["frio", "congelado"] }
            ]
            // Object.keys(order?.carriers) carriers
          }
          {!!Object.entries(order?.carriersData || {}).length && (
            <>
              <b>Transportistas:</b>
              <List>
                {Object.keys(order?.carriers || {})
                  .sort((a, b) => a.localeCompare(b))
                  .map((c) => (
                    <>
                      <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        {c}{" "}
                        <Sf
                          q={
                            c.charAt(0) === "c"
                              ? 2
                              : c.charAt(0) === "f"
                              ? "1"
                              : 0
                          }
                        />
                      </Typography>
                      {Object.entries(order?.carriersData || {})
                        // eslint-disable-next-line max-len
                        ?.filter(([id]) => id === order?.carriers?.[c])
                        ?.map(([id, data], index) => (
                          <CarrierList
                            key={index}
                            carriers={carriers}
                            order={order}
                            c={c}
                            id={id}
                            data={data}
                          />
                        ))}
                    </>
                  ))}
              </List>
            </>
          )}
          {order?.prevRoadmaps && (
            <List>
              <Typography sx={{ fontWeight: "bold" }}>
                Hojas de rutas anteriores
              </Typography>
              {prevRoadmaps?.map((a, indx) => (
                <Link key={indx} to={`/app/roadmaps/${a?.id}`}>
                  <MenuItem
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >{`${a?.data?.shipping} ${moment(a?.date).format(
                    "DD/MM",
                  )} - ${a?.data?.driver || ""}`}</MenuItem>
                </Link>
              ))}
            </List>
          )}
        </>
      ),
      infoExtra: (
        <Chip
          label={shipmentStates?.[order?.shipmentState] ?? ""}
          color={shipmentStatesColors[order?.shipmentState]}
        />
      ),
    },
    {
      label: "Comentarios",
      children: (
        <>
          {order.comments && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: 1,
                  borderRadius: 5,
                  background: "#ccc",
                  margin: ".5rem 0",
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Comentarios de la orden:
                </Typography>{" "}
                <Typography
                  style={{ background: "primary", fontSize: ".9rem" }}
                >
                  {" "}
                  {order.comments}
                </Typography>
                <EditIcon onClick={() => setNewComment(true)} />
              </Box>
              <Box>
                <Typography sx={{ marginY: 1, fontWeight: "bold" }}>
                  Observaciones
                </Typography>
                <Stack direction="column" gap={1}>
                  {order?.observations?.map(
                    ({ title, details, view }, index) => (
                      <Stack
                        flexWrap="wrap"
                        sx={{
                          p: 1,
                          borderRadius: 2,
                          backgroundColor: "lightgray",
                        }}
                        direction={"row"}
                        key={index}
                      >
                        <Typography
                          sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                        >
                          {title}:{" "}
                        </Typography>
                        <Typography>{details}</Typography>
                        <Stack
                          direction="row"
                          gap="4px"
                          flexWrap="wrap"
                          sx={{ marginTop: "6px" }}
                        >
                          {view.map((view) => (
                            <Typography
                              key={view}
                              sx={{
                                fontWeight: 700,
                                color: "black",
                                backgroundColor: "gray",
                                p: "2px",
                                borderRadius: 1,
                              }}
                            >
                              {view === "driver"
                                ? "chófer"
                                : view === "roadmap"
                                ? "hoja de ruta"
                                : view === "remito"
                                ? "armado"
                                : null}
                            </Typography>
                          ))}
                        </Stack>
                      </Stack>
                    ),
                  )}
                </Stack>
              </Box>
            </>
          )}
          {order.customerComments && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 1,
                borderRadius: 5,
                background: "tomato",
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                Comentarios del usuario:
              </Typography>{" "}
              <Typography style={{ background: "primary", fontSize: ".9rem" }}>
                {" "}
                {order.customerComments}
              </Typography>
            </Box>
          )}
          <AddNewComment />
          <AddObservations observations={order?.observations?.length} />
          <GenericModal
            width="fit-content"
            open={openObservations}
            handleClose={toggleObservationsModal}
          >
            <EditObservations
              order={order}
              toggleVisibility={toggleObservationsModal}
              observations={order.observations}
              orderID={order.objectID}
            />
          </GenericModal>
        </>
      ),
    },
    {
      label: "Reparto",
      children: (
        <>
          {!editShipping && (
            <>
              <Typography
                style={{ cursor: "pointer" }}
                onClick={() => setEditShipping(true)}
              >
                {order?.shipping?.title} <EditIcon />
              </Typography>
            </>
          )}
          {editShipping && (
            <Select
              defaultValue={order?.shipping?.objectID}
              onBlur={updateShipping}
            >
              {_.map(shippings, ({ objectID, title }, index) => (
                <MenuItem key={index} value={objectID}>
                  {title ?? ""}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      ),
    },
    {
      label: "Pagos",
      gridXS: 4,
      children: <PaymentsCard order={order} />,
      infoExtra: (
        <Chip
          label={
            paymentStates[order.paymentState] || paymentStates[order.status]
          }
          size="small"
          color={orderStatesColors[order?.status]}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: order?.canceled
          ? "#FF6565"
          : order?.invoiceDriveId
          ? "#C6FBBA"
          : "#fff",
        margin: "0 0 .8rem 0",
        boxShadow: "1px 1px 1px 1px rgb(0 0 0 / 10%)",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar variant="square" />
          <span
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              <span>{order.internalId_daily}</span>
              <Link
                style={{
                  textDecoration: "unset",
                  color: "black",
                  marginLeft: 2,
                }}
                to={`/app/stores/${order.store?.objectID}`}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "1.5rem",
                    fontWeigth: "bold",
                    display: "inline",
                  }}
                >
                  {order.store?.storeName}
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: "unset", color: "black" }}
                to={`/app/users/${order?.user?.objectID}`}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeigth: "bold",
                    display: "inline",
                  }}
                >
                  {" "}
                  -{order.user?.name}
                </Typography>
              </Link>
            </span>
            <Typography
              sx={{ color: "black", fontSize: ".9rem", display: "inline" }}
            >
              <b>Fecha de compra: </b>
              {moment(order.timestamp?.toLocaleString()).format(
                "DD/MM/YYYY HH:mm",
              )}
            </Typography>
          </span>
        </Box>
        <div>
          <Button
            id="basic-button"
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={(e) => setOpenMenu(e.currentTarget)}
          >
            <MoreVertIcon style={{ color: "black" }} />
          </Button>
          <Menu
            // sx={{ top: '139px', left: '367px' }}
            id="basic-menu"
            anchorEl={openMenu}
            open={openMenu}
            onClose={close}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <FormControlLabel
                control={<Checkbox checked={order?.canceled} />}
                onChange={(e) => {
                  updateDoc({
                    indexName: "orders",
                    id: order?.objectID,
                    hit: { canceled: e.target.checked },
                  });
                }}
                label="Cancelado"
              />
            </MenuItem>
          </Menu>
        </div>
      </Box>
      <Grid sx={{ marginTop: "1rem", padding: ".5rem 0" }} container>
        {gridItemsHeader.map(
          ({ label, headerLabel, children, link }, index) => (
            <ItemHeader
              key={index}
              headerLabel={headerLabel}
              label={label}
              link={link}
            >
              {children && children}
            </ItemHeader>
          ),
        )}
      </Grid>
      <Box
        sx={{
          background: "#e6e6e6",
          width: "100%",
          maxWidth: "1800px",
          padding: "1rem 0",
        }}
      >
        <Grid spacing={1} container>
          {gridAccordionHeader.map(
            ({ label, children, icon, infoExtra, gridXS }, index) => (
              <AccordionHeader
                key={index}
                Icon={icon}
                gridXS={gridXS}
                // eslint-disable-next-line react/no-children-prop
                children={children}
                labelHeader={label}
                infoExtra={infoExtra}
              />
            ),
          )}
        </Grid>
      </Box>
    </Box>
  );
}
