/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Drawer, IconButton, List, Tooltip } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  Home as HomeIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// icons sets
import "@fortawesome/fontawesome-free/css/all.min.css";
// styles
import useStyles from "./styles";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// context
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import { useAreaDataContext } from "../../context/AreaContext";
import { useRoleDataContext } from "../../context/RoleContext";
// import "font-awesome/css/font-awesome.min.css";

// const structure = [
//   { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
//   {
//     id: 1,
//     label: "Typography",
//     link: "/app/typography",
//     icon: <TypographyIcon />,
//   },
//   { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
//   { id: 15, label: "Clientes", link: "/app/clientes", icon: <i className="fa fa-google" /> },
//   { id: 16, label: "Familias", link: "/app/familias", icon: <TableIcon /> },
//   { id: 17, label: "Areas", link: "/app/areas", icon: <TableIcon /> },
//
//   {
//     id: 3,
//     label: "Notifications",
//     link: "/app/notifications",
//     icon: <NotificationsIcon />,
//   },
//   {
//     id: 4,
//     label: "UI Elements",
//     link: "/app/ui",
//     icon: <UIElementsIcon />,
//     children: [
//       { label: "Icons", link: "/app/ui/icons" },
//       { label: "Charts", link: "/app/ui/charts" },
//       { label: "Maps", link: "/app/ui/maps" },
//     ],
//   },
//   { id: 5, type: "divider" },
//   { id: 6, type: "title", label: "HELP" },
//   { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
//   { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
//   { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
//   { id: 10, type: "divider" },
//   { id: 11, type: "title", label: "PROJECTS" },
//   {
//     id: 12,
//     label: "My recent",
//     link: "",
//     icon: <Dot size="large" color="warning" />,
//   },
//   {
//     id: 13,
//     label: "Starred",
//     link: "",
//     icon: <Dot size="large" color="primary" />,
//   },
//   {
//     id: 14,
//     label: "Background",
//     link: "",
//     icon: <Dot size="large" color="secondary" />,
//   },
// ];

function useAreaPanelstructure() {
  const areaData = useAreaDataContext();
  const roleData = useRoleDataContext();
  const dashboard = !!areaData.dashboard?.[roleData.objectID];
  const [structure, setStructure] = useState([]);

  useEffect(() => {
    let s =
      roleData?.view
        ?.filter((d) => !!areaData.panel[d])
        .map((d) => ({
          id: d,
          label: areaData.panel[d].title || d,
          link: `/app/${d}`,
          icon: <i className={`fa ${areaData.panel[d].icon || "fa-google"}`} />,
        })) || [];
    if (dashboard) {
      s = [
        {
          id: 0,
          label: "Inicio",
          link: "/app/dashboard",
          icon: <HomeIcon />,
        },
        ...s,
      ];
    }
    setStructure(s);
  }, [areaData, roleData, dashboard]);

  return structure;
}

function Sidebar({ location = {} }) {
  const classes = useStyles();
  const theme = useTheme();
  const structure = useAreaPanelstructure();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List
        className={classes.sidebarList}
        sx={{
          transition: "background-color 0.3s ease-in-out",
          
        }}
        >
        {structure.map((link) =>
          isSidebarOpened ? (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ) : (
            <Tooltip key={link.id} title={link.label} arrow placement="right">
              <div>
                <SidebarLink
                  key={link.id}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              </div>
            </Tooltip>
          )
        )}
      </List>
    </Drawer>
  );

  // ##################################################################
}

export default withRouter(Sidebar);
