/* eslint-disable */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputBase,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { withStyles } from "@material-ui/styles";

export const sellers = {
  Vívet: "v",
  "Luz Azul": "luz_azul",
};

export const assemblyStates = {
  checked: "Controlado",
  assembled: "Armado",
  no: "Sin armar",
  assembling: "Armando",
  inBoxes: "En cajas",
};

export const assemblyStatesColors = {
  inBoxes: "success", //"info",
  assembling: "secondary",
  assembled: "primary",
  checked: "success",
  no: "error",
};

export const orderStates = {
  in_process: "En proceso",
  pending_payment: "Pendiente de pago",
  payed: "Pago",
};

export const orderStatesColors = {
  in_process: "info",
  payed: "success",
  pending_payment: "error",
};

export const booleanTypes = {
  true: "Si",
  false: "No",
};

export const shipmentStates = {
  pending: "Pendiente",
  assigned: "Asignada",
  shipped: "En viaje",
  delivered: "Entregado",
  cancelled: "Cancelado",
  rejected: "Rechazado",
  takeaway: "Retira",
  arriving: "Llegando",
  in_transit: "En Camino",
};

export const shipmentStatesColors = {
  pending: "info",
  assigned: "secondary",
  shipped: "primary",
  delivered: "success",
  cancelled: "error",
  rejected: "error",
  takeaway: "info",
  arriving: "secondary",
};

export const taskStates = {
  pending: "Pendiente",
  assigned: "Asignada",
  shipped: "En progreso",
  delivered: "Realizado",
  cancelled: "Cancelado",
};

export const roadmapStates = {
  pending: "En armado",
  ready: "Ordenada",
  shipped: "En reparto",
  delivered: "Entregado",
};

export const excelOptimoCaminoHeader = [
  [
    "Nombre",
    "Calle y altura",
    "CP",
    "Ciudad",
    "Provincia",
    "Pais",
    "Indicacion (piso, departamento)",
    "Entrega (E) / Recogida (R)",
    "Num. Orden de pedido",
    "Telefono",
    "Email",
    "Cantidad de bultos",
    "Peso (si configurado)",
    "Volumen (si configurado)",
    "Tiempo en destino (min)",
    "Ventana de tiempo 1 - Desde",
    "Ventana de tiempo 1 - hasta",
    "Ventana de tiempo 2 - Desde",
    "Ventana de tiempo 2 - hasta",
    "Notas",
    "Especialidad (como en su config.)",
    "Latitud",
    "Longitud",
    "Personalizado 1",
    "Personalizado 2",
    "Personalizado 3",
    "Personalizado 4",
  ],
];

export const excelContingenciaHeader = [
  [
    "Numero",
    "Cliente",
    "Dirección",
    "Localidad",
    "Horarios",
    "CONGELADO",
    "FRIO",
    "SECO",
    "Forma de Pago",
    "Monto total",
    "Responsable Ventas",
    "Responsable Cobranzas",
  ],
];

export const basketStates = {
  borrowed: "Prestado",
  inTruck: "En camión",
  inPallet: "En pallet",
  returned: "En la empresa",
  divided: "Dividido",
};

export const productTypes = {
  seco: 0,
  frio: 1,
  congelado: 2,
};

export const productTypeNames = {
  0: "Seco",
  1: "Frio",
  2: "Congelado",
};

export const cancellRoadmapOptions = [
  "No llegamos con el horario pactado",
  "No se encuentra en la dirección",
  "No puede recibir el pedido",
  "Inconveniente con la camioneta ",
  "No sé pudo despachar (Interior)",
];

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function CommonRefinementList({ items, refine, title, options = null }) {
  return (
    <>
      <h5>{title}</h5>
      <ul style={{ listStyleType: "none", padding: "0px" }}>
        {items.map((item, i) => (
          <li key={i}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isRefined}
                  onClick={() => refine(item.value)}
                />
              }
              label={`${options ? options[item.label] : item.label} (${
                item.count
              })`}
            />
          </li>
        ))}
      </ul>
    </>
  );
}

export const SearchBox = ({ currentRefinement, refine }) => (
  <InputBase
    style={{ padding: "12px" }}
    placeholder="Buscar…"
    inputProps={{ "aria-label": "search" }}
    fullWidth
    value={currentRefinement}
    onChange={(event) => refine(event.currentTarget.value)}
    startAdornment={<SearchIcon />}
  />
);

export const PaginationView = ({ currentRefinement, nbPages, refine }) => (
  <>
    <Pagination
      style={{ marginTop: "10px" }}
      variant="outlined"
      count={nbPages}
      page={currentRefinement}
      onChange={(event, value) => {
        refine(value);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }}
    />
  </>
);

function autofitColumns(json, worksheet) {
  let objectMaxLength = [];
  json.forEach((jsonData) => {
    Object.entries(jsonData).forEach(([, v], idx) => {
      let columnValue = "" + v;
      objectMaxLength[idx] =
        objectMaxLength[idx] >= columnValue.length
          ? objectMaxLength[idx]
          : columnValue.length;
    });
  });
  const wscols = objectMaxLength.map((w) =>
    w > 8 ? { width: w } : { width: 8 },
  );
  worksheet["!cols"] = wscols;
}

export const exportToExcel = ({ headers, apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let wb = XLSX.utils.book_new();
  let ws = XLSX.utils.aoa_to_sheet(headers);
  XLSX.utils.sheet_add_json(ws, apiData, { origin: "A2", skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "Datos");
  autofitColumns(apiData, ws);
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function extractFromAddress(components, type, name = "long_name") {
  return components
    ? components
        .filter((component) => component.types.indexOf(type) === 0)
        .map((item) => item[name])
        .pop() || ""
    : "";
}

export function extractLocalidad(components) {
  if (_.isNil(components)) return "";

  const provincia = extractFromAddress(
    components,
    "administrative_area_level_1",
    "short_name",
  );
  if (provincia && /Provincia.*Buenos.*Aires/g.test(provincia)) {
    let localidad = [];
    localidad.push(extractFromAddress(components, "locality", "short_name"));
    localidad.push(
      extractFromAddress(
        components,
        "administrative_area_level_2",
        "short_name",
      ),
    );
    return localidad
      .filter((x) => typeof x === "string" && x.length > 0)
      .join(", ");
  } else if (provincia && /CABA/g.test(provincia)) {
    let localidad = [];
    localidad.push(
      extractFromAddress(components, "sublocality_level_1", "short_name"),
    );
    localidad.push(
      extractFromAddress(
        components,
        "administrative_area_level_1",
        "short_name",
      ),
    );
    return localidad
      .filter((x) => typeof x === "string" && x.length > 0)
      .join(", ");
  } else {
    let localidad = [];
    localidad.push(
      extractFromAddress(
        components,
        "administrative_area_level_2",
        "short_name",
      ),
    );
    localidad.push(
      extractFromAddress(
        components,
        "administrative_area_level_1",
        "short_name",
      ),
    );
    return localidad
      .filter((x) => typeof x === "string" && x.length > 0)
      .join(", ");
  }
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const getDaysBetweenDates = function (startDate, endDate) {
  const now = moment(startDate).clone(),
    dates = [];

  while (now.isSameOrBefore(endDate, "day")) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }
  return dates;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const isExpired = (date) => {
  if (!date) {
    return false;
  }
  const day = moment(date, "DD/MM/YYYY");
  const today = moment();
  return day < today;
};

export const isNearExpiration = (date) => {
  if (!date) {
    return false;
  }
  const day = moment(date, "DD/MM/YYYY").subtract(30, "days");
  const today = moment();
  return day < today;
};

export const getTruckerColor = (trucker) => {
  let color = "";
  if (_.isNil(trucker)) return color;
  color =
    isNearExpiration(trucker.driver_license_duedate) ||
    isNearExpiration(trucker.linti_duedate) ||
    isNearExpiration(trucker.linti_course_duedate) ||
    isNearExpiration(trucker.linti_psico_duedate) ||
    isNearExpiration(trucker.health_card_duedate)
      ? "#ffffb3"
      : color;
  color =
    isExpired(trucker.driver_license_duedate) ||
    isExpired(trucker.linti_duedate) ||
    isExpired(trucker.linti_course_duedate) ||
    isExpired(trucker.linti_psico_duedate) ||
    isExpired(trucker.health_card_duedate)
      ? "#fb8072"
      : color;
  return color;
};

export function ProductView({ product }) {
  // const [product, setProduct] = useState();
  //
  // useEffect(() => {
  //     getObjects("products",[oid]).then(products => {
  //         setProduct(products[oid])
  //     })
  // }, [oid])

  return (
    <>
      {product && (
        <Grid container alignItems={"center"}>
          <Grid item xs={12} sm={4} style={{ fontWeight: "bold" }}>
            {_.get(product, `brandName`, "")}
          </Grid>
          {/*<Grid item xs={4} sm={2}><img style={{width: "100%", maxWidth: "50px"}} src={product.thumbnail} alt={product.name}/></Grid>*/}
          <Grid item xs={12} sm={8}>
            {[product.name]}
            {product.taste &&
              ` - ${product.taste} (${product.quantity}${product.unit})`}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const ConfirmDialog = ({ title, description, children }) => {
  const [open, setOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const show = (callback) => (event) => {
    event.preventDefault();

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };

    setOpen(true);
    setCallback(() => () => callback(event));
  };

  const hide = () => {
    setOpen(false);
    setCallback(null);
  };

  const confirm = () => {
    callback();
    hide();
  };

  return (
    <>
      {children(show)}
      {open && (
        <Dialog open={open} aria-labelledby="confirm-dialog">
          <DialogTitle id="confirm-dialog">{title}</DialogTitle>
          <DialogContent>{description}</DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={hide}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={confirm}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
